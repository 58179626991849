import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    ImgWrap,
    Img,
    ContactWrap,
    Contact,
    ImgFooter,
    FooterLinkItemsDois,
    Row,
    Col
} from './FooterElements'

import satc from './img/satc-logoo.png'
import ods from './img/movimento_ods.png'
import bairro from './img/bairro.png'
import fapesc from './img/fapesc.png'


const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <ImgWrap>
                    <Img src='images/logo_rodape.svg' alt='17 objetivos ODS' />
                </ImgWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems onClick={() => {window.open('https://web.satc.edu.br/', '_blank');}}>
                            <FooterLinkTitle>Realização</FooterLinkTitle>
                            <Row>
                            <Col><ImgFooter src={satc}></ImgFooter></Col>
                            </Row>
                        </FooterLinkItems>
                        <FooterLinkItemsDois>
                            <FooterLinkTitle>Apoio</FooterLinkTitle>

                        <Row>
                          <Col><ImgFooter onClick={() => {window.open('http://www.bairrodajuventude.org.br/', '_blank');}} src={bairro}></ImgFooter></Col>
                          <Col><ImgFooter onClick={() => {window.open('https://www.fapesc.sc.gov.br/', '_blank');}} src={fapesc}></ImgFooter></Col>

                        </Row>
                            
            

                        </FooterLinkItemsDois>
                        <FooterLinkItems onClick={() => {window.open('https://sc.movimentoods.org.br/', '_blank');}}>
                            <FooterLinkTitle>Parceiro</FooterLinkTitle>
                            <Row>
                            <Col><ImgFooter src={ods}></ImgFooter></Col>
                            </Row>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <ContactWrap>
                    <Contact>(48)3431-7628 ods2030@satc.edu.br - Criciúma - SC</Contact>
                </ContactWrap>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
