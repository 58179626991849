import React, {useState} from 'react'
import './App.css';
import Sidebar from './components/Sidebar/index';
import Navbar from './components/Navbar/index';
import Footer from './components/Footer/index';
import Home from './pages/index';
import Ods from './pages/ods'
import Sobre from './pages/sobre';
import VoceSabia from './pages/vocesabia';
import Videos from './pages/videos';
import Jogos from './pages/jogos'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  }
 
  return (
    <Router>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/ods" component={Ods} exact />
        <Route path="/sobre" component={Sobre} exact />
        <Route path="/voce-sabia" component={VoceSabia} exact />
        <Route path="/videos" component={Videos} exact />
        <Route path="/jogos" component={Jogos} exact />
      </Switch> 
      <Footer />
    </Router>
  );
}

export default App;
