import React from 'react'
import {
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarLink,
    SidebarWrapper,
    SidebarMenu,
    SideBtnWrap,
    SidebarRoute
} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarRoute to='/' onClick={toggle}>
                        Home
                    </SidebarRoute>
                    <SidebarRoute to='/ods' onClick={toggle}>
                        Os ODS
                    </SidebarRoute>
                    <SidebarRoute to='/jogos' onClick={toggle}>
                        Jogos
                    </SidebarRoute>
                    <SidebarRoute to='/sobre'>
                        Sobre nós
                    </SidebarRoute>
                    <SidebarRoute to='/voce-sabia'>
                        Você sabia?
                    </SidebarRoute>
                    <SidebarRoute to='/videos'>
                        Vídeos
                    </SidebarRoute>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
