import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const BackModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const BodyModal = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: #c7f3ff;
  position: absolute;
  border-radius: 10px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  @media screen and (max-width: 768px){
    width: 90%;
  }
`;

export const ContentModalBody = styled.div`
  height: 90%;
  overflow-y: auto;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const ContainerCloseModalButton = styled.div`
  height: 8%;
`;