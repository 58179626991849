export const quizData = [
  {
    id: 0,
    question:"PARA QUE OCORRA O DESENVOLVIMENTO SUSTENTÁVEL É PRECISO HAVER INTERAÇÃO ENTRE OS SETORES?",
    options: ['AMBIENTAL, ECONÔMICO E SAÚDE','AMBIENTAL, SOCIAL E HABITACIONAL','SOCIAL, AMBIENTAL E ECONÔMICO'],
    answer: 'SOCIAL, AMBIENTAL E ECONÔMICO'
  },
  {
    id: 1,
    question:"EM QUE ANO FOI ESTABELECIDA A AGENDA 2030 COM OS OBJETIVOS DE DESENVOLVIMENTO SUSTENTÁVEL (ODS)?",
    options: ['2017','2013','2015','2010'],
    answer: '2015'
  },
  {
    id: 2,
    question:"QUANTOS OBJETIVOS COMPÕE OS ODS?",
    options: ['12','17','8','15'],
    answer: '17'
  },
  {
    id: 3,
    question:"A ÁGUA UTILIZADA NO BENEFICIAMENTO DO CARVÃO MINERAL PASSA POR UM PROCESSO DE TRATAMENTO E PODE SER REUTILIZADA NOVAMENTE NO LAVADOR DA MINA. ESSA ATIVIDADE ESTÁ RELACIONADA COM QUAL ODS?",
    options: ['ODS 12 - CONSUMO E PRODUÇÃO RESPONSÁVEL','ODS 6 - ÁGUA POTÁVEL E SANEAMENTO','ODS 14 – VIDA NA ÁGUA','ODS 11 – CIDADES E COMUNIDADES SUSTENTÁVEIS'],
    answer: 'ODS 12 - CONSUMO E PRODUÇÃO RESPONSÁVEL'
  },
  {
    id: 4,
    question:"UMA DAS FORMAS DE COLABORAR COM A PRESERVAÇÃO DO MEIO AMBIENTE É REDUZIR A PRODUÇÃO DE RESÍDUOS. O QUE VOCÊ PODE FAZER PARA COLABORAR COM ISSO?",
    options: ['COMPRANDO PRODUTOS COM EMBALAGENS NÃO RECICLÁVEIS','REUTILIZADO MATERIAIS E EMBALAGENS, SEMPRE QUE POSSÍVEL','COLABORANDO COM A COLETA SELETIVA','PRATICANDO OS 3 RS – REDUZIR, REUTILIZAR E RECICLAR'],
    answer: 'PRATICANDO OS 3 RS – REDUZIR, REUTILIZAR E RECICLAR'
  },
  {
    id: 5,
    question:"O MOVIMENTO ODS SANTA CATARINA TEM COMO MISSÃO FACILITAR A INCORPORAÇÃO DOS OBJETIVOS DE DESENVOLVIMENTO SUSTENTÁVEL (ODS) NO DIA A DIA DAS PESSOAS E NA PRÁTICA DAS ORGANIZAÇÕES CATARINENSES. COMO VOCÊ PODE CONTRIBUIR COM ISSO?",
    options: ['SENDO VOLUNTÁRIO DO MOVIMENTO','AUXILIANDO NA IMPLANTAÇÃO DOS ODS NA SUA ESCOLA/EMPRESA','COLABORANDO COM PROJETOS SOCIAIS E AMBIENTAIS','TODAS AS ALTERNATIVAS ESTÃO CORRETAS'],
    answer: 'TODAS AS ALTERNATIVAS ESTÃO CORRETAS'
  },
  {
    id: 6,
    question:"A FUNDAÇÃO DE AMPARO A PESQUISA DE SANTA CATARINA (FAPESC) TEM COMO MISSÃO PROMOVER O ECOSSISTEMA CATARINENSE DE CIÊNCIA, TECNOLOGIA E INOVAÇÃO POR MEIO DE FOMENTO E DA INTEGRAÇÃO DE SEUS AGENTES. A MISSÃO DA FAPESC TEM RELAÇÃO COM QUAL ODS",
    options: ['ODS 1 – ERRADICAÇÃO DA POBREZA','ODS 4 -  EDUCAÇÃO DE QUALIDADE','ODS 8 -  TRABALHO DESCENTE E CRESCIMENTO ECONÔMICO','ODS 9 – INDÚSTRIA, INOVAÇÃO E INFRAESTRUTURA'],
    answer: 'ODS 9 – INDÚSTRIA, INOVAÇÃO E INFRAESTRUTURA'
  },
  {
    id: 7,
    question:"O QUE É AGENDA 2030?",
    options: ['UMA AGENDA DE COMPROMISSOS PARA O ANO DE 2030','PLANO DE AÇÃO GLOBAL QUE REÚNE OS 17 ODS E SUAS METAS','DOCUMENTO QUE REÚNE O COMPROMISSO DOS GOVERNADORES DOS ESTADOS BRASILEIROS'],
    answer: 'PLANO DE AÇÃO GLOBAL QUE REÚNE OS 17 ODS E SUAS METAS'
  },
  {
    id: 8,
    question:"QUAL ODS FALA SOBRE ERRADICAR TODAS AS FORMAS DE DISCRIMINAÇÃO E EMPODERAR MULHERES E MENINAS?",
    options: ['ODS 1 – ERRADICAÇÃO DA POBREZA','ODS 10 – REDUÇÃO DAS DESIGUALDADES','ODS 5 – IGUALDADE DE GÊNERO','ODS 3 – SAÚDE E BEM ESTAR'],
    answer: 'ODS 5 – IGUALDADE DE GÊNERO'
  },
  {
    id: 9,
    question:"O ODS 12 TRATA DO CONSUMO CONSCIENTE. COMO PODEMOS FAZER ISSO?",
    options: ['TROCANDO TODOS OS NOSSOS OBJETOS SEMPRE QUE UM NOVO DO MESMO TIPO FOR LANÇADO.','USAR A MANGUEIRA PARA LAVAR O QUINTAL E O CARRO.','COMPARTILHANDO COM OS AMIGOS OS LIVROS QUE VOCÊ JÁ LEU OU OS BRINQUEDOS QUE JÁ NÃO QUER BRINCAR MAIS.','AUMENTAR O CONSUMO DE MATERIAIS NÃO RECICLÁVEIS'],
    answer: 'COMPARTILHANDO COM OS AMIGOS OS LIVROS QUE VOCÊ JÁ LEU OU OS BRINQUEDOS QUE JÁ NÃO QUER BRINCAR MAIS.'
  }
]

export default quizData