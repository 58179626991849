import React from 'react'
import Services from '../components/Info';
import Baloes from '../components/Baloes/BaloesTeste';

const Ods = () => {

    return (
        <>
            <Baloes />
            <Services />
        </>
    )
}

export default Ods