import React, {useState} from 'react'
import {Button} from '../ButtonElement'
import { 
    HomeContainer, 
    HomeBg,
    ImageBg, 
    HomeContent,
    HomeP,
    HomeBtnWrapper,
    ArrowForward,
    ArrowRight 
} from './HomeElements'


const HomeSection = () => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HomeContainer>
            <HomeBg>
                <ImageBg src="./images/pagina_inicial.jpg" alt="natureza"/>
                
            </HomeBg>
            <HomeContent>
                <HomeP>
                    VIVEMOS NO PLANETA CHAMADO TERRA. É UM LOCAL LINDO, COM MUITA ÁGUA, FLORESTAS E ANIMAIS INCRÍVEIS.
                    E ATÉ ONDE SABEMOS, TRATA-SE DO ÚNICO PLANETA HABITÁVEL DO SISTEMA SOLAR.
                </HomeP>
                <HomeP>
                    MAS ALGUMAS COISAS NO NOSSO PLANETA NÃO ESTÃO MUITO LEGAIS.
                </HomeP>
                <HomeP>
                    AS ÁRVORES JÁ NÃO SÃO TANTAS, AS ÁGUAS JÁ NÃO SÃO TÃO CRISTALINAS E ALGUNS ANIMAIS ESTÃO ENTRANDO EM EXTINÇÃO. MAS, AS MUDANÇAS NÃO PARAM POR AÍ, VIRA E MEXE OUVIMOS FALAR SOBRE AS CRIANÇAS QUE ESTÃO MORRENDO DE FOME OU SEM PODER IR PARA A ESCOLA, PAÍSES QUE ESTÃO EM GUERRAS. ISSO É MUITO CHATO.
                </HomeP>
                <HomeP>CUIDAR DO PLANETA, É CUIDAR DO OUTRO E DE NÓS MESMOS! E VOCÊ SABE COMO PODEMOS FAZER ISSO?</HomeP>
                <HomeP>NESTE SITE VOCÊ VAI APRENDER COMO VOCÊ, SEUS AMIGOS, SUA FAMÍLIA, SUA ESCOLA PODEM FAZER PARA CUIDAR DO PLANETA TERRA!</HomeP>
                <HomeBtnWrapper>
                    <Button to='/ods' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
                        VAMOS LÁ? {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HomeBtnWrapper>
            </HomeContent>
        </HomeContainer>
    )
}

export default HomeSection
