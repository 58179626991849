import React from 'react'
import CardJogos from '../components/Jogos';


const Jogos = () => {

    return (
        <>
            <CardJogos />
        </>
    )
}

export default Jogos