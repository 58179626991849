import React from 'react'
import {
    InfoContainer,
    InfoWrapper,
    InfoCard,
    SubContainer,
    InfoH4,
    InfoH3,
    InfoP
} from './SobreElements'

const InfoSection = () => {
    return (
        <>
            <InfoContainer>

                <InfoH3>SOBRE</InfoH3>
                <SubContainer>
                    <InfoP>O PROJETO CARTILHA INTERATIVA VISA TRANSMITIR O CONHECIMENTO SOBRE OS OBJETIVOS DE DESENVOLVIMENTO SUSTENTÁVEL (ODS) PARA CRIANÇAS E ADOLESCENTES DE 6 A 14 ANOS, DE FORMA LÚDICA E INTUITIVA POR MEIO DA TECNOLOGIA, POSSIBILITANDO O ACESSO DE QUALQUER LUGAR.</InfoP>
                    <InfoP>ALINHADO A TEMÁTICA AMBIENTAL, O PROJETO FOI DESENVOLVIDO COM O FOCO NOS ODS, TOMANDO COMO BASE A CARTILHA “17 FORMAS DE MUDAR O MUNDO” PRODUZIDA PELO PROJETO “EDUCAÇÃO DE CRIANÇAS E JOVENS PARA SUSTENTABILIDADE”, DESENVOLVIDO PELO BAIRRO DA JUVENTUDE, EM PARCERIA COM O MOVIMENTO NACIONAL ODS SANTA CATARINA.</InfoP>
                
                    <InfoP>DESENVOLVIDO PELA SATC – ASSOCIAÇÃO BENEFICENTE DA INDÚSTRIA CARBONÍFERA DE SANTA CATARINA, E FINANCIADO PELA FAPESC – FUNDAÇÃO DE AMPARO À PESQUISA E INOVAÇÃO DO ESTADO DE SANTA CATARINA (TERMO DE OUTORGA 2017TR1881), O PROJETO POSSIBILITOU A CONSEÇÃO DE BOLSAS DE PESQUISA PARA ALUNOS DO ENSINO MÉDIO, TÉCNICO E GRADUAÇÃO DA SATC E DE OUTRAS INSTITUIÇÕES DE ENSINO DA BACIA CARBONIFERA, COM O OBJETIVO DE CAPACITAR ESSES ALUNOS PARA A INICIAÇÃO CIENTÍFICA E ATUAÇÃO NA ÁREA DE PESQUISA.</InfoP>
                    <InfoP>O TERMO DE OUTORGA 2017TR1881, AO QUAL ESSE PROJETO DE PESQUISA ESTÁ VINCULADO, ESTÁ ASSOCIADO À CADEIA PRODUTIVA DO CARVÃO MINERAL – FASE 2: EXTRAÇÃO, BENEFICIAMENTO, CONVERSÃO E MEIO AMBIENTE. </InfoP>
                </SubContainer>

                <InfoH3>REALIZAÇÃO</InfoH3>
                <SubContainer>
                    <InfoP underline="underline" title="600">SATC - ASSOCIAÇÃO BENEFICENTE DA INDÚSTRIA CARBONÍFERA DE SANTA CATARINA</InfoP>
                </SubContainer>

                <InfoH3>APOIO</InfoH3>
                <SubContainer>
                    <InfoP underline="underline" title="600">FAPESC - FUNDAÇÃO DE AMPARO À PESQUISA E INOVAÇÃO DO ESTADO DE SANTA CATARINA</InfoP>
                    <InfoP >A FAPESC TEM COMO MISSÃO PROMOVER O ECOSSISTEMA CATARINENSE DE CIÊNCIA, TECNOLOGIA E INOVAÇÃO POR MEIO DE FOMENTO E DA INTEGRAÇÃO DE SEUS AGENTES, OBJETIVANDO O AVANÇO DE TODAS AS ÁREAS DO CONHECIMENTO, O EQUILÍBRIO REGIONAL, O DESENVOLVIMENTO ECONÔMICO SUSTENTÁVEL E A MELHORIA DA QUALIDADE DE VIDA DO CIDADÃO CATARINENSE</InfoP>
                    
                    <InfoP top="20px" underline="underline" title="600">BAIRRO DA JUVENTUDE – PROJETO EDUCAÇÃO DE CRIANÇAS E JOVENS PARA SUSTENTABILIDADE</InfoP>
                    <InfoP marginBotton="6px">O BAIRRO DA JUVENTUDE É UMA INSTITUIÇÃO DA SOCIEDADE CIVIL SEM FINS LUCRATIVOS QUE VISA PROMOVER, POR MEIO DA EDUCAÇÃO E ASSISTÊNCIA SOCIAL, A GARANTIA DE DIREITOS PARA CRIANÇAS, ADOLESCENTES E JOVENS. LOCALIZADO EM CRICIÚMA/SC, ATENDE MAIS DE 1.500 CRIANÇAS, JOVENS E SUAS FAMÍLIAS.</InfoP>
                    <InfoP >O PROJETO EDUCAÇÃO DE CRIANÇAS E JOVENS PARA SUSTENTABILIDADE, QUE SERVIU DE INSPIRAÇÃO PARA O NOSSO PROJETO, TEVE COMO OBJETIVO PROMOVER A EDUCAÇÃO PARA A SUSTENTABILIDADE DESDE A INFÂNCIA, A FIM DE CONSTRUIR UM FUTURO INCLUSIVO, RESILIENTE E SUSTENTÁVEL PARA TODAS AS PESSOAS E PARA O PLANETA.</InfoP>
                </SubContainer>

                <InfoH3>PARCEIRO</InfoH3>
                <SubContainer>
                    <InfoP underline="underline" title="600">MOVIMENTO NACIONAL ODS SANTA CATARINA</InfoP>
                    <InfoP >MOVIMENTO SOCIAL CONSTITUÍDO POR VOLUNTÁRIOS, DE CARÁTER APARTIDÁRIO, PLURAL E ECUMÊNICO, COM A FINALIDADE DE CONTRIBUIR PARA A MELHORIA DA QUALIDADE DE VIDA DA SOCIEDADE CATARINENSE. VISA A CONSTRUÇÃO DE UMA SOCIEDADE MELHOR, SOCIALMENTE INCLUSIVA, AMBIENTALMENTE SUSTENTÁVEL E ECONOMICAMENTE EQUILIBRADA POR MEIO DOS COMPROMISSOS DA AGENDA 2030 PARA O DESENVOLVIMENTO SUSTENTÁVEL, APROVADA PELOS PAÍSES MEMBROS DA ONU DURANTE SUA 70ª ASSEMBLEIA GERAL REALIZADA EM SETEMBRO DE 2015, EM NOVA YORK.</InfoP>
                </SubContainer>

                <SubContainer marginTop="20px" marginBottom="30px">
                    <InfoWrapper>
                        <InfoCard>
                            <InfoH4>PROJETO CARTILHA INTERATIVA</InfoH4>
                            <InfoP><span>REALIZAÇÃO:</span> SATC</InfoP>
                            <InfoP><span>APOIO:</span> FAPESC E BAIRRO DA JUVENTUDE</InfoP>
                            <InfoP marginBotton="15px"><span>PARCEIRO:</span> MOVIMENTO NACIONAL ODS SANTA CATARINA</InfoP>
                            <InfoP><span>COORDENAÇÃO:</span> LUCIANO DAGOSTIN BILESSIMO</InfoP>
                            <InfoP><span>SUPERVISÃO:</span> EDSON MATEUS DA CRUZ</InfoP>
                            <InfoP><span>BOLSISTA FAPESC:</span> BRUNO SILVA DANIEL</InfoP>
                            <InfoP><span>APOIO:</span> DANIEL BASILIO MARCELO, REGINA FREITAS FERNADES, GUSTAVO DOS SANTOS DE LUCCA, ANDERSON RODRIGO FARIAS, DIEGO MINATTO, JOÃO JACY FERNANDES DA SILVA</InfoP>
                        </InfoCard>
                        <InfoCard>
                            <InfoH4>PROJETO EDUCAÇÃO DE CRIANÇAS E JOVENS PARA SUSTENTABILIDADE</InfoH4>
                            <InfoP><span>REALIZAÇÃO:</span> BAIRRO DA JUVENTUDE</InfoP>
                            <InfoP><span>APOIO:</span> CMDCA E ENGIE BRASIL ENERGIA</InfoP>
                            <InfoP marginBotton="15px">PARCEIRO: MOVIMENTO NACIONAL ODS SANTA CATARINA</InfoP>
                            <InfoP><span>TEXTOS:</span> REGINA MAY DE FARIAS</InfoP>
                            <InfoP><span>COLABORAÇÃO:</span> CARIN SALVAN BERGMANN</InfoP>
                            <InfoP><span>COORDENAÇÃO GERAL:</span> REGINA MAY DE FARIAS</InfoP>
                            <InfoP><span>COORDENAÇÃO GERAL DO BAIRRO DA JUVENTUDE:</span> NETO NUNES</InfoP>
                            <InfoP><span>MONITORA DE PROJETOS DO BAIRRO DA JUVENTUDE:</span> PAOLA DE SOUZA</InfoP>
                            <InfoP><span>COORDENAÇÃO PEDAGÓGICA:</span> ANDREIA CRISTINA ANTUNES MASTELA</InfoP>
                            <InfoP><span>ASSISTENTE ADMINISTRATIVO:</span> DANIELA MILANEZZE</InfoP>
                            <InfoP><span>REVISÃO DE CONTEÚDO:</span> JULIANA BILESSIMO MELLER E MICHELE PICOLO</InfoP>
                        </InfoCard>
                    </InfoWrapper>
                </SubContainer>
                
            </InfoContainer> 
        </>
    )
}

export default InfoSection
