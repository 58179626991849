import React from 'react'
import ODS4 from '../../images/ods4.svg'
import ODS12 from '../../images/ods12.svg'
import ODS8 from '../../images/ods8.svg'
import ODS9 from '../../images/ods9.svg'
import ODS13 from '../../images/ods13.svg'
import ODS17 from '../../images/ods17.svg'
import { 
    VoceSabiaContainer,
    VoceSabiaH1,
    VoceSabiaWrapper,
    VoceSabiaCard,
    VoceSabiaIcon,
    VoceSabiaH2,
    VoceSabiaP
} from './VoceSabiaElements'

const VoceSabia = () => {
    return (
        <>
            <VoceSabiaContainer>
                <VoceSabiaH1>Você Sabia?</VoceSabiaH1>
                <VoceSabiaWrapper>
                    <VoceSabiaCard small="true" color="#c81b2b">
                        <VoceSabiaIcon src={ODS4}/>
                        <VoceSabiaH2>ODS 4</VoceSabiaH2>
                        <VoceSabiaP>AS EMPRESAS MINERADORAS INVESTEM 1% DO SEU FATURAMENTO NA SATC. COM A SATC, O SETOR CARBONÍFERO OPORTUNIZA ENSINO DE QUALIDADE PARA PESSOAS DE BAIXA RENDA, ATRAVÉS DA DISPONIBILIZAÇÃO DE BOLSAS DE ESTUDO.</VoceSabiaP>
                    </VoceSabiaCard>
                    
                    <VoceSabiaCard small="true" color="#a41642">
                        <VoceSabiaIcon src={ODS8}/>
                        <VoceSabiaH2>ODS 8</VoceSabiaH2>
                        <VoceSabiaP>EM 2017 A INDÚSTRIA DO CARVÃO MINERAL NO BRASIL POSSUÍA 14 EMPRESAS EM OPERAÇÃO E EMPREGA, DIRETAMENTE, MAIS DE CINCO MIL PESSOAS. NOS ESTADOS DO RIO GRANDE DO SUL, SANTA CATARINA E PARANÁ, A CADEIA TERMELÉTRICA GERA EM TORNO DE 53 MIL EMPREGOS E MOVIMENTA CERCA DE R$ 12 BILHÕES POR ANO.</VoceSabiaP>
                    </VoceSabiaCard>
                    <VoceSabiaCard small="true" color="#f26a2a">
                        <VoceSabiaIcon src={ODS9}/>
                        <VoceSabiaH2>ODS 9</VoceSabiaH2>
                        <VoceSabiaP>A FUNDAÇÃO DE AMPARO A PESQUISA DE SANTA CATARINA (FAPESC) TEM COMO MISSÃO PROMOVER O ECOSSISTEMA CATARINENSE DE CIÊNCIA, TECNOLOGIA E INOVAÇÃO POR MEIO DE FOMENTO E DA INTEGRAÇÃO DE SEUS AGENTES, OBJETIVANDO O AVANÇO DE TODAS AS ÁREAS DO CONHECIMENTO, O EQUILÍBRIO REGIONAL, O DESENVOLVIMENTO ECONÔMICO SUSTENTÁVEL E A MELHORIA DA QUALIDADE DE VIDA DO CIDADÃO CATARINENSE.</VoceSabiaP>
                    </VoceSabiaCard>
                </VoceSabiaWrapper>
                <VoceSabiaWrapper>
                    <VoceSabiaCard color="#c08e28">
                        <VoceSabiaIcon src={ODS12}/>
                        <VoceSabiaH2>ODS 6</VoceSabiaH2>
                        <VoceSabiaP>TODAS A ÁGUA UTILIZADA NO PROCESSO DE EXTRAÇÃO DE CARVÃO MINERAL PASSA POR UM A ESTAÇÃO DE TRATAMENTO DE EFLUENTES, QUE APÓS TRATADA PODE SER REUTILIZADA NO PROCESSO PRODUTIVO OU DESCARTADA NO MEIO AMBIENTE.</VoceSabiaP>
                    </VoceSabiaCard>
                    <VoceSabiaCard color="#3e8044">
                        <VoceSabiaIcon src={ODS13}/>
                        <VoceSabiaH2>ODS 13</VoceSabiaH2>
                        <VoceSabiaP>O CRESCENTE DEBATE MUNDIAL SOBRE MUDANÇAS CLIMÁTICAS COLOCOU FORTE FOCO SOBRE O CARVÃO MINERAL E O SEU APROVEITAMENTO PARA A GERAÇÃO DE ENERGIA.
CIENTE DA NECESSIDADE DE SE ADAPTAR ÀS NOVAS DEMANDAS AMBIENTAIS, DE DEMONSTRAR A SUA VIABILIDADE COMO FONTE SUSTENTÁVEL DE ENERGIA, NOVAS TECNOLOGIAS ESTÃO SENDO ESTUDADAS, DENTRE ELAS A GASEIFICAÇÃO DE CARVÃO, CAPTURA E ARMAZENAMENTO DE CO2, INDÚSTRIA CARBOQUÍMICA, ENTRE OUTRAS.</VoceSabiaP>
                    </VoceSabiaCard>
                    <VoceSabiaCard color="#0c476b">
                        <VoceSabiaIcon src={ODS17}/>
                        <VoceSabiaH2>ODS 17</VoceSabiaH2>
                        <VoceSabiaP>AS PARCERIAS SÃO MUITO IMPORTANTE PARA QUE TODOS OS ODS SEJAM ALCANÇADOS. A REALIZAÇÃO DESSE PROJETO SÓ FOI POSSÍVEL PORQUE A SATC CONTOU COM IMPORTANTES PARCEIROS. SEM A AJUDA DA FAPESC, MOVIMENTO NACIONAL ODS SANTA CATARINA E O BAIRRO DA JUVENTUDE, NÃO TERÍAMOS CONSEGUIDO SOZINHOS. PORQUE NÓS SABEMOS QUE É MUITO DIFÍCIL SALVAR O PLANETA SOZINHO, MAS NÃO É IMPOSSÍVEL QUANDO TODOS AJUDAM.</VoceSabiaP>
                    </VoceSabiaCard>
                </VoceSabiaWrapper>
            </VoceSabiaContainer>
        </>
    )
}

export default VoceSabia