import {React, useState} from 'react';
import Modal from '../Modal';
import ImageMapper from 'react-image-mapper';
import { ods1, ods2, ods3, ods4, ods5, ods6, ods7, ods8, ods9, ods10, ods11, ods12, ods13, ods14, ods15, ods16, ods17} from './Data'
import styled from 'styled-components'

const BaloesContainer = styled.div`
    height: auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #b4deec; //#c7f3ff;

    @media screen and (max-width: 768px){
        height: auto;
    }

    @media screen and (max-width: 400px){
        height: auto;
    }
`

const BaloesWrapper = styled.div`
    max-width: 1000px;
    //margin: 0 auto;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    align-items: center;
    //grid-gap: 16px;
    
    //padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 1px;
    }
`

const BaloesCard = styled.div`
    background: #c7f3ff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    //border-radius: 10px;
    //max-height: 340px;
    min-width: 250px;
    //padding: 30px;
    //box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
`

const BaloesIcon = styled.img`
    heigth: 600px;//160px;
    width: 500px;//160px;
    background: black;
    object-fit: cover;
    //margin-bottom: 10px;
`
const BaloesH1 = styled.h1`
    font-size: 2rem;
    color: #de1768;
    margin-top: 20px;
    //margin-bottom: 44px;

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
    }
`
const BaloesH2 = styled.h2`
    font-size: 2rem;
    color: #de1768;
    //margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
    }
`

/*
areas: [
            { name: "Ods1", shape: "poly", coords: [110,156,100,162,95,174,103,254,110,266,121,271,202,263,213,256,219,245,211,165,203,152,191,147],strokeColor: 'rgba(0, 0, 0, 0)', fillColor:"rgba(0,0,0,0)" },
            { name: "Ods2", shape: "poly", coords: [220,98,206,107,202,120,204,150,211,160,213,171,217,206,229,212,273,207,275,192,285,182,319,177,311,162,304,93,293,90], strokeColor: 'rgba(0, 0, 0, 0)', fillColor:"rgba(0,0,0,0)" },
            { name: "Ods3", shape: "poly", coords: [319,62,307,72,304,83,313,161,319,171,328,175,382,170,381,149,386,138,394,132,425,129,420,70,411,59,398,53], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods4", shape: "poly", coords: [398,133,388,140,384,149,387,229,390,238,397,242,458,236,466,240,472,244,487,243,495,238,501,228,498,147,490,134,479,129],strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"  },
            { name: "Ods5", shape: "poly", coords: [289,184,278,192,276,206,287,281,294,292,308,297,360,289,359,264,364,253,373,246,389,242,385,230,383,178,374,173],strokeColor: 'rgba(0, 0, 0, 0)'  , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods6", shape: "poly", coords: [219,230,220,244,215,254,204,263,188,266,195,326,245,322,255,326,262,333,265,342,278,339,277,318,282,305,292,296,287,284,276,225], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)" },
            { name: "Ods7", shape: "poly", coords: [163,332,151,341,148,352,156,429,163,440,173,446,255,438,266,431,272,420,264,339,256,328,243,323], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods8", shape: "poly", coords: [294,299,284,306,279,316,282,395,287,406,296,414,380,410,394,404,398,393,393,310,384,298,372,294], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods9", shape: "poly", coords: [376,246,365,254,361,264,363,291,378,294,388,300,394,310,398,359,466,353,476,347,483,335,475,254,466,243,454,239], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"}
        ],
areas: [
            { name: "Ods1", shape: "poly", coords: [54, 267, 125, 259, 224, 248, 245, 256, 262, 271, 270, 312, 285, 443, 270, 472, 248, 482, 97, 498, 69, 492, 51, 475, 33, 307, 39, 286],strokeColor: 'rgba(0, 0, 0, 0)', fillColor:"rgba(0,0,0,0)" },
            { name: "Ods2", shape: "poly", coords: [256, 251, 248, 186, 261, 161, 285, 146, 437, 130, 455, 133, 472, 273, 482, 293, 496, 304, 496, 307, 428, 320, 405, 334, 395, 355, 399, 370, 301, 379, 281, 370], strokeColor: 'rgba(0, 0, 0, 0)', fillColor:"rgba(0,0,0,0)" },
            { name: "Ods3", shape: "poly", coords: [478, 280, 452, 117, 467, 86, 493, 76, 648, 55, 676, 67, 686, 95, 700, 212, 645, 215, 623, 227, 612, 249, 613, 294, 595, 298, 570, 296, 504, 305], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods4", shape: "poly", coords: [857, 404, 847, 244, 821, 213, 711, 214, 642, 219, 621, 232, 614, 255, 615, 304, 621, 360, 630, 426, 637, 440, 740, 431, 765, 431, 788, 439, 816, 444, 838, 433],strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"  },
            { name: "Ods5", shape: "poly", coords: [631, 440, 611, 309, 585, 298, 428, 320, 403, 343, 398, 369, 418, 513, 430, 538, 452, 549, 571, 536, 566, 476, 584, 447],strokeColor: 'rgba(0, 0, 0, 0)'  , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods6", shape: "poly", coords: [398, 400, 282, 414, 290, 443, 271, 478, 242, 490, 220, 490, 237, 608, 319, 599, 341, 602, 357, 611, 371, 629, 374, 639, 407, 639, 403, 590, 408, 572, 434, 550, 453, 555, 430, 543, 418, 518], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)" },
            { name: "Ods7", shape: "poly", coords: [161, 620, 146, 639, 141, 662, 157, 825, 178, 843, 210, 849, 369, 832, 389, 805, 372, 631, 342, 605, 318, 602], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods8", shape: "poly", coords: [412, 751, 419, 768, 432, 781, 458, 784, 612, 777, 633, 760, 644, 741, 632, 568, 610, 550, 585, 545, 454, 550, 425, 557, 406, 579], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods9", shape: "poly", coords: [573, 541, 570, 482, 584, 453, 661, 439, 738, 434, 779, 435, 795, 452, 817, 631, 807, 650, 780, 663, 645, 677, 637, 571, 607, 544], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"}
        ],

areas: [
            { name: "Ods10", shape: "poly", coords: [451, 233, 457, 107, 473, 92, 492, 81, 662, 87, 676, 96, 691, 121, 683, 296, 666, 313, 594, 307, 578, 319, 471, 313, 454, 291], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods11", shape: "poly", coords: [691, 276, 707, 297, 808, 309, 830, 290, 913, 293, 923, 284, 929, 112, 915, 85, 887, 74, 721, 73, 707, 85, 695, 103], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods12", shape: "poly", coords: [933, 269, 945, 157, 965, 126, 991, 119, 1131, 130, 1157, 136, 1174, 155, 1178, 178, 1162, 336, 1146, 358, 1112, 365, 999, 353, 956, 347, 935, 328], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods13", shape: "poly", coords: [606, 545, 600, 590, 514, 585, 496, 601, 486, 631, 394, 623, 375, 606, 366, 575, 380, 423, 399, 399, 420, 393, 558, 402, 565, 405, 560, 520, 569, 528], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods14", shape: "poly", coords: [563, 384, 570, 332, 593, 313, 763, 312, 785, 326, 799, 349, 792, 479, 792, 519, 766, 528, 731, 527, 707, 543, 613, 544, 568, 522, 569, 412], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods15", shape: "poly", coords: [802, 323, 795, 489, 801, 503, 816, 516, 993, 531, 1020, 511, 1034, 357, 950, 352, 929, 330, 931, 293, 834, 294], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods16", shape: "poly", coords: [478, 760, 486, 797, 514, 819, 684, 827, 710, 801, 723, 616, 685, 593, 520, 588, 501, 602, 489, 640], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods17", shape: "poly", coords: [699, 598, 727, 616, 718, 756, 892, 766, 920, 751, 929, 718, 936, 563, 920, 542, 905, 536, 736, 528, 711, 547], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
        ],
*/

var dados;

const BaloesTeste = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [isMore, setIsMore] = useState(false)
    
    const URLBOY = "./images/menino.png";//boy-mobile.png
    const URLGIRL = "./images/menina.png";//girl-mobile2.png
    const MAPBOY ={
        name: "Baloes-boy",
        areas: [
            { name: "Ods1", shape: "poly", coords: [109,156,98,166,95,177,102,254,109,266,121,272,202,263,212,257,219,248,211,163,201,152,191,147],strokeColor: 'rgba(0, 0, 0, 0)', fillColor:"rgba(0,0,0,0)" },
            { name: "Ods2", shape: "poly", coords: [216,99,205,107,201,120,205,153,211,161,216,206,222,211,229,213,272,208,274,195,280,188,288,182,322,177,315,167,311,160,303,92,294,89], strokeColor: 'rgba(0, 0, 0, 0)', fillColor:"rgba(0,0,0,0)" },
            { name: "Ods3", shape: "poly", coords: [320,62,308,69,303,81,313,159,319,170,330,175,382,169,381,150,386,138,395,131,425,129,420,70,411,59,398,53], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods4", shape: "poly", coords: [399,132,388,138,383,148,387,225,391,237,397,242,457,236,466,238,472,244,487,243,496,236,501,228,499,144,492,133,479,127],strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"  },
            { name: "Ods5", shape: "poly", coords: [290,183,279,190,274,205,286,282,292,291,303,297,362,289,359,261,364,251,371,245,392,242,385,227,382,177,370,172],strokeColor: 'rgba(0, 0, 0, 0)'  , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods6", shape: "poly", coords: [219,230,220,248,213,257,202,264,188,266,194,326,244,322,255,326,261,334,263,340,276,340,275,317,283,303,297,296,286,286,275,225], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)" },
            { name: "Ods7", shape: "poly", coords: [161,330,152,339,147,352,155,427,162,440,176,446,248,438,263,434,271,422,262,338,253,327,240,323], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods8", shape: "poly", coords: [295,299,281,308,277,319,281,396,286,407,298,415,380,411,392,403,398,389,394,312,385,300,373,295], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods9", shape: "poly", coords: [375,246,364,254,361,265,364,292,380,295,389,302,395,312,398,359,465,353,480,348,483,335,476,257,469,244,456,237], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"}
        ],
    }
    const MAPGIRL ={
        name: "Baloes-girl",
        areas: [
            { name: "Ods10", shape: "poly", coords: [214,65,201,72,196,84,193,163,199,175,208,180,256,182,265,178,301,178,308,168,312,87,307,75,295,68], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods11", shape: "poly", coords: [333,59,320,67,315,78,311,156,316,167,324,175,367,177,380,169,420,169,426,164,430,80,423,70,413,64], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods12", shape: "poly", coords: [456,83,444,90,438,101,432,179,438,189,444,198,525,205,539,201,546,190,553,110,548,98,538,90], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods13", shape: "poly", coords: [175,220,164,226,158,234,152,316,157,328,167,334,209,337,214,327,219,320,227,313,267,314,268,294,255,289,245,280,248,225], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods14", shape: "poly", coords: [266,179,255,186,250,197,248,278,256,288,270,293,318,295,324,289,331,284,359,285,363,270,364,199,359,187,346,180], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods15", shape: "poly", coords: [385,170,374,174,366,183,365,266,369,277,378,284,458,288,470,283,476,272,481,203,446,198,435,190,430,180,431,171], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods16", shape: "poly", coords: [229,316,218,324,211,338,208,411,214,423,225,430,303,434,317,430,323,417,326,338,320,324,307,318], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
            { name: "Ods17", shape: "poly", coords: [335,285,324,291,318,300,317,319,325,328,328,342,325,402,410,405,421,399,429,392,433,309,427,296,415,288], strokeColor: 'rgba(0, 0, 0, 0)' , fillColor:"rgba(0,0,0,0)"},
        ],
    }

    const handleClick = (area) => {
        console.log(area.name)
        switch(area.name){
            case "Ods1": dados = ods1;
                         break;
            case "Ods2": dados = ods2;
                         break;
            case "Ods3": dados = ods3;
                         break;
            case "Ods4": dados = ods4;
                         break;
            case "Ods5": dados = ods5;
                         break;
            case "Ods6": dados = ods6;
                         break;
            case "Ods7": dados = ods7;
                         break;
            case "Ods8": dados = ods8;
                         break;
            case "Ods9": dados = ods9;
                         break;
            case "Ods10": dados = ods10;
                         break;
            case "Ods11": dados = ods11;
                         break;
            case "Ods12": dados = ods12;
                         break;
            case "Ods13": dados = ods13;
                         break;
            case "Ods14": dados = ods14;
                         break;
            case "Ods15": dados = ods15;
                         break;
            case "Ods16": dados = ods16;
                         break;
            case "Ods17": dados = ods17;
                         break;
        }
        setIsOpen(true);
    }
    //<BaloesIcon src='./images/boy-mobile.png'/>
    //<BaloesIcon src='./images/girl-mobile2.png'/>
    return (
        <>
            <BaloesContainer>
                <BaloesH1>CLIQUE NOS BALÕES E</BaloesH1>
                <BaloesH2>CONHEÇA OS ODS</BaloesH2>
                <BaloesWrapper>
                    <BaloesCard>
                        <ImageMapper src={URLBOY} map={MAPBOY} onClick={area => handleClick(area)} width={window.innerWidth > 700 ? 500 : window.innerWidth} imgWidth={619}/>
                    </BaloesCard>
                    <BaloesCard>
                        <ImageMapper src={URLGIRL} map={MAPGIRL} onClick={area => handleClick(area)} width={window.innerWidth > 700 ? 500 : window.innerWidth} imgWidth={618}/>
                    </BaloesCard>
                </BaloesWrapper>
            </BaloesContainer>
            <Modal open={isOpen} onClose={() => setIsOpen(false)} more={isMore} onGoBack={() => setIsMore(!isMore)} dados={dados} />
        </>
    )
}

export default BaloesTeste
