import React from 'react'
import Videos from '../components/Videos';


const Extras = () => {

    return (
        <>
            <Videos />
        </>
    )
}

export default Extras