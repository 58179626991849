import styled from 'styled-components'

export const InfoContainer = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #b4deec;

    @media screen and (max-width: 768px){
        height: auto;
        padding-bottom: 15px;
    }

    @media screen and (max-width: 400px){
        height: auto;
        padding-bottom: 15px;
    }
`

export const InfoWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    //padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        //padding: 0 20px;
    }
`

export const InfoCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    border-radius: 10px;
    min-height: 400px;
    min-width: 250px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px){
        min-height: auto;
        max-width: 350px;
    }
`

export const InfoH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const InfoH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px
`

export const InfoP = styled.p`
    font-size: 1rem;
    //text-align: center;
`