import React, { useRef } from 'react';

import {DragDropContext} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import Puzzle from '../../react-image-puzzle';

import Quebra from './img/quebra-cabeca.jpg'
import './index.css';

function is_touch_device() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}

const PuzzleGame = () => {
  const puzzleRef = useRef(null);

  // Detect if a touch device
  if (is_touch_device()) {
    puzzleRef.current = DragDropContext(TouchBackend)(Puzzle);
  } else {
    puzzleRef.current = DragDropContext(HTML5Backend)(Puzzle);
  }
 
  const PuzzleWithHandler = puzzleRef.current;
  
  return( 
    <div className="fundo"> 
      <div className="puzzle-full">
        <PuzzleWithHandler
          className="puzzletest"
          image={Quebra}
          onDone={() => {
            console.log("Parabéns"); 
          }}
          size={window.innerWidth > 700 ? 500 : 300}
          level={4}
        />
      </div> 
    </div>
  )
}

export default PuzzleGame;

