import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Parent = styled.div`
  vertical-align: middle;
  text-align: center;
 `

 export const Button = styled.p`
   padding: 15px;
   margin: 0 auto;
   width: 200px;
   margin-top: 10px;
   border: 0 solid white;
   border-radius: 60px;
   background-color: #0398DA;
 `

 export const Ganhou = styled.div`
 
 border-radius: 10px;
 text-shadow: 1px 1px black;
 font-weight: bold;
 color: white;
 font-size: 20px;
   text-align: center;
   width: 40%;
   height: 40%;
   min-width: 350px;
   
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   margin: auto;
   max-width: 100%;
   max-height: 100%;
   overflow: hidden;
 `

export const Fundo = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  max-width: 100px;
  max-height: 100px;

  @media screen and (max-width: 700px){
    max-width: 50px;
    max-height: 50px;
  }

  @media screen and (max-width: 360px){
    max-width: 48px;
    max-height: 48px;
  }

  @media screen and (max-width: 360px){
    max-width: 44px;
    max-height: 44px;
  }

`


export const Child = styled.div`
position: relative;
  display: inline-block;
  background-color: #800080;
  width: 115px;
  height: 115px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
  border-radius: 5px;
  border: 1px solid #01344f;
  cursor: pointer;

  @media screen and (max-width: 700px){
      width: 57px;
      height: 62px;
  }

  @media screen and (max-width: 360px){
    width: 54px;
    height: 56px;
  }

  @media screen and (max-width: 320px){
    width: 50px;
    height: 56px;
  }

`