export const ods1 = {
    h1Text: "1 - Erradicação da Pobreza",
    mainText: "O ODS 1 BUSCA ACABAR COM A POBREZA EXTREMA PARA TODAS AS PESSOAS E EM TODOS OS LUGARES. EXISTEM MILHARES DE CRIANÇAS SEM CONDIÇÕES DE SE ALIMENTAR TODOS OS DIAS, SEM TER ÁGUA, SEM TER UMA BOA CASA PARA MORAR E SEM PODER BRINCAR PORQUE TEM QUE TRABALHAR. PARA QUE O ODS 1 SEJA ALCANÇADO, AS PESSOAS, AS EMPRESAS E O PODER PÚBLICO PRECISAM TRABALHAR JUNTOS, PARA MELHORAR A VIDA DAS CRIANÇAS, DOS ADOLESCENTES, DOS ADULTOS E DOS IDOSOS PARA QUE TODOS TENHAM ACESSO AOS SEUS DIREITOS E VIVAM BEM.",
    firstTopic: ['DOAR OS BRINQUEDOS E ROUPAS QUE NÃO USA MAIS. ','ECONOMIZAR O SEU DINHEIRO, A SUA MESADA.'],
    secondTopic: ['INCLUIR PESSOAS DE BAIXA RENDA NO MERCADO DE TRABALHO E APOIAR A GERAÇÃO ALTERNATIVA DE RENDA.','CAPACITAR AS PESSOAS PARA A SUA PROFISSIONALIZAÇÃO.','OFERTAR CURSOS DE EMPREENDEDORISMO.','FOMENTAR O MICROEMPREENDEDOR INDIVIDUAL – MEI.','FOMENTAR OS NEGÓCIOS SOCIAS, NEGÓCIOS VERDES, SOLIDÁRIOS E A ECONOMIA INCLUSIVA.','CRIAR TECNOLOGIAS MAIS ACESSÍVEIS ÀS POPULAÇÕES MAIS POBRES.','BUSCAR FONTES DE RECURSOS PARA O DESENVOLVIMENTO DO MUNICÍPIO.','CONHECER AS CAUSAS DA POBREZA NA SUA COMUNIDADE, NO PAÍS E NO MUNDO.','SER SOLIDÁRIO E VOLUNTÁRIO EM CAUSAS SOCIAS.','ALIAR-SE A PROJETOS SOCIAS E APOIAR AS ORGANIZAÇÕES NÃO GONVERNAMENTAIS.','DOAR ALIMENTOS PARA OS MAIS NECESSITADOS.','PARTICIPAR DE AÇÕES E PROJETOS NO MUNICÍPIO.'],
    image: "./images/modal/ods1.svg",
    color: "#e5233d"
}

export const ods2 = {
    h1Text: "2 - Fome Zero e Agricultura Sustentável",
    mainText: "SABE AQUELA SENSAÇÃO DE FALTA DE ENERGIA, DE FRAQUEZA, ÀS VEZES ATÉ DE DOR, PODEM SER CAUSADAS POR FALTA DE ALIMENTOS. PARA FICARMOS FORTES E SAUDÁVEIS, NOSSO CORPO PRECISA DE VITAMINAS, MINERAIS E NUTRIENTES QUE ESTÃO NAS FRUTAS, VERDURAS, LEGUMES, CARNES, OVOS, LEITE, PEIXES, ETC. IMAGINA UMA PESSOA FICAR VÁRIOS DIAS SEM COMER, OU COMENDO SÓ UM TIPO DE COMIDA? CADA UM DEVE FAZER A SUA PARTE E OS GOVERNOS PRECISAM CRIAR POLÍTICAS PARA PROMOVER A AGRICULTURA SUSTENTÁVEL, PROTEGENDO AS NOSSAS FLORESTAS E NASCENTES",
    firstTopic: ['NÃO DESPERDIÇAR COMIDA.','PREFIRA FRUTAS, VERDURAS E LEGUMES ORGÂNICOS E PRODUZIDOS PELA AGRICULTURA SUSTENTÁVEL.','SE POSSÍVEL, FAÇA UMA HORTA NA SUA CASA, ESCOLA OU COMUNIDADE.'],
    secondTopic: ['PARTICIPAR DA PASTORAL DA CRIANÇA E OUTROS MOVIMENTOS EM PROL DA ALIMENTAÇÃO.','DOAR ALIMENTOS PARA PESSOAS E ORGANIZAÇÕES.','REDUZIR O DESPERDÍCIO DE ALIMENTOS.','INCENTIVAR PROGRAMAS DE APOIO À MERENDA ESCOLAR E EDUCAÇÃO ALIMENTAR.','ENSINAR ORGANIZAÇÕES SOCIAS E FAMILIARES A APROVEITAREM MELHOR OS ALIMENTOS, EVITANDO O DESPERDÍCIO.','CRIAR CURSOS DE EXTENSÃO SOBRE NUTRIÇÃO.','AUMENTAR A PRODUÇÃO DE ALIMENTOS SAUDÁVEIS.','REDUZIR OS PREÇOS DOS ALIMENTOS NUTRITIVOS.','INSTALAR REFEITÓRIOS NAS EMPRESAS E FORNECER REFEIÇÕES PARA OS COLABORADORES.','IMPLANTAR RESTAURANTES POPULARES (DE BAIXO PREÇO).','ESTIMULAR A AGRICULTURA FAMILIAR E COMUNITÁRIA DE SUBSISTÊNCIA.','COMPRAR PRODUTOS DA AGRICULTURA FAMILIAR.'],
    image: "./images/modal/ods2.svg",
    color: "#dda73a"
}

export const ods3 = {
    h1Text: "3 - Saúde e Bem-estar",
    mainText: "COM CERTEZA ALGUMA VEZ NA SUA VIDA VOCÊ JÁ FICOU DOENTE, NÃO É MESMO? SABE COMO É CHATO NÃO PODER BRINCAR OU ESTUDAR PORQUE A SAÚDE NÃO ESTÁ LEGAL. É POR ISSO QUE EXISTE UM ODS PARA QUE TODOS FIQUEM BEM. MUITAS DOENÇAS ESTÃO VOLTANDO PORQUE AS PESSOAS NÃO ESTÃO SE CUIDANDO DIREITO. OUTRAS SÃO CAUSADAS PORQUE ELAS NÃO SE ALIMENTAM BEM, NÃO PRATICAM ESPORTES OU NÃO FAZEM QUALQUER TIPO DE ATIVIDADE FÍSICA.",
    firstTopic: ['TOME TODAS AS VACINAS CORRETAMENTE.','COMA ALIMENTOS SAUDÁVEIS E SEMPRE BEBA ÁGUA FILTRADA.','LAVE SEMPRE AS MÃOS E LAVE BEM AS FRUTAS ANTES DE COMER.','BRINQUE, DANCE E SE EXERCITE.'],
    secondTopic: ['INCENTIVAR A AMAMENTAÇÃO.','VACINAR AS CRIANÇAS E ACOMPANHAR A SAÚDE DOS FILHOS.','INCLUIR OS ATOS DE HIGIENE NA EDUCAÇÃO DAS CRIANÇAS.','REALIZAR PALESTRAS SOBRE OS CUIDADOS COM A SAÚDE.','CRIAR ESPAÇOS PARA AS MULHERES AMAMENTAREM SEUS FILHOS.','APOIAR O TRABALHO DA PASTORAL DA CRIANÇA E OUTRAS INSTITUIÇÕES QUE TRABALHAM EM PROL DO DESENVOLVIMENTO INFANTIL.','PROMOVER A PREVENÇÃO SOBRE AS DIFERENTES FORMAS DE VIOLÊNCIA CONTRA A CRIANÇA.','REALIZAR PROGRAMAS DE ACESSO A ÁGUA POTÁVEL PARA A POPULAÇÃO.','CONSCIENTIZAR SOBRE A IMPORTÂNCIA DO PRÉ-NATAL E SAÚDE DA GESTANTE E RECÉM-NASCIDO.','INCENTIVAR PROGRAMAS EDUCACIONAIS, EM COMUNIDADES CARENTES, DE ESCLARECIMENTO SOBRE HIGIENE PESSOAL E SANITÁRIA, ALEITAMENTO MATERNO E NUTRIÇÃO INFANTIL.'],
    image: "./images/modal/ods3.svg",
    color: "#4ca146"
}

export const ods4 = {
    h1Text: "4 - Educação de Qualidade",
    mainText: "COMO ESTÃO SEUS ESTUDOS? COMO É A SUA ESCOLA? TEM MATERIAIS, ESPAÇOS E PROFESSORES PARA TODOS OS ALUNOS? A ESCOLA FICA PERTO OU LONGE DA SUA CASA? PARECEM PERGUNTAS BOBAS, MAS NÃO SÃO. SABIA QUE MUITAS CRIANÇAS NÃO PODEM IR PARA A AULA POIS SEU PAÍS ESTÁ EM GUERRA? OU PORQUE A ESCOLA FICA MUITO LONGE DE SUA CASA E NÃO TEM TRANSPORTE? ACREDITA QUE EXISTEM ESCOLAS QUE NÃO TEM NEM ONDE AS CRIANÇAS SENTAREM E NEM MERENDA? A EDUCAÇÃO É UM DIREITO DE TODOS E OS GOVERNOS DEVEM CRIAR POSSIBILIDADES PARA QUE TODAS AS CRIANÇAS, JOVENS E ADULTOS TENHAM OPORTUNIDADES DE APRENDER SEMPRE.",
    firstTopic: ['NADA DE FALTAR AULA POR PREGUIÇA.','ESTUDE SEMPRE E FAÇA SEUS DEVERES DE CASA.','DOE OS LIVROS QUE NÃO PRECISAR MAIS OU OS QUE JÁ LEU.','AJUDAR SEU COLEGA A ESTUDAR.'],
    secondTopic: ['REALIZAR PALESTRAS SOBRE OS ODS NAS ESCOLAS E COMUNIDADES.','ORGANIZAR GINCANAS DE VOLUNTARIADO E CIDADANIA.','AMPLIAR INICIATIVAS DE INCLUSÃO E ACESSIBILIDADE NAS ESCOLAS E UNIVERSIDADES.','PROPORCIONAR INTERCÂMBIOS COM OUTROS PAÍSES.','APOIAR PROGRAMAS DE EDUCAÇÃO, CAPACITAÇÃO E INCLUSÃO DIGITAL DE CRIANÇAS E JOVENS PARA FUTURA INSERÇÃO NO MERCADO DE TRABALHO.','REALIZAR O PROGRAMA JOVEM APRENDIZ.','MELHORAR OS EQUIPAMENTOS DAS ESCOLAS BÁSICAS','FORNECER MATERIAL DIDÁTICO E DE LEITURA','REDUZIR A EVASÃO ESCOLAR','IMPLEMENTAR PROGRAMAS DE FORMAÇÃO DE PROFESSORES DE ESCOLAS PÚBLICAS','CONSTRUIR E MANTER BIBLIOTECAS, VIDEOTECAS OU BRINQUEDOTECAS NA COMUNIDADE, ESCOLAS OU ORGANIZAÇÕES SOCIAS','VALORIZAR A EDUCAÇÃO E ACOMPANHAR O DESENVOLVIMENTO DE SEUS FILHOS'],
    image: "./images/modal/ods4.svg",
    color: "#c7212f"
}

export const ods5 = {
    h1Text: "5 - Igualdade de Gênero",
    mainText: "VOCÊ SABE O QUE É IGUALDADE DE GÊNERO? É GARANTIR QUE TODAS AS MENINAS E MULHERES TENHAM OS MESMOS DIREITOS E OPORTUNIDADES QUE OS MENINOS E HOMENS. SE MENINOS E MENINAS NÃO TIVEREM AS MESMAS OPORTUNIDADES, ELES PODEM NÃO TER A CHANCE DE FAZER ALGO EM QUE SÃO BONS. POR EXEMPLO, SE A MARTA NÃO TIVESSE TIDO A CHANCE DE JOGAR FUTEBOL, ELA NUNCA SERIA A MELHOR JOGADORA DE FUTEBOL DO MUNDO! MENINOS E MENINAS PRECISAM SER RESPEITADOS PELAS SUAS ESCOLHAS, CAPACIDADES E POR SUAS DIFERENÇAS. RESPEITANDO AS DIFERENÇAS, APOIANDO NOS ESTUDOS E INCENTIVANDO NA PROFISSÃO, MENINOS E MENINAS PODEM SE TORNAR ADULTOS INCRÍVEIS!",
    firstTopic: ['TRATE TODOS IGUALMENTE','RESPEITE HOMENS E MULHERES QUE CUIDAM DA FAMÍLIA','NÃO ACEITE A VIOLÊNCIA CONTRA MULHERES E MENINAS','BUSQUE SEMPRE A DIVISÃO POR IGUAL DAS TAREFAS'],
    secondTopic: ['VALORIZAR O TRABALHO DOMÉSTICO','CRIAR OPORTUNIDADES DE INSERÇÃO DE MÃO-DE-OBRA FEMINA NO MERCADO DE TRABALHO','INCLUIR A VALORIZAÇÃO DO TRABALHO DA MULHER EM PROGRAMAS DE DIVERSIDADE','IMPLANTAR PROGRAMAS DE CAPACITAÇÃO E MELHORIA NA QUALIFICAÇÃO DAS MULHERES','SENSIBILIZAR MULHERES A ASSUMIREM POSTOS DE TRABALHO GERALMENTE OCUPADOS POR HOMENS','PROMOVER PALESTRAS SOBRE AUTOESTIMA, GESTÃO DE CARREIRA','PROMOVER UMA FEIRA DAS PROFISSÕES VALORIZANDO O PAPEL DA MULHER NA SOCIEDADE','VALORIZAR AÇÕES COMUNITÁRIAS QUE ENVOLVAM O TRABALHO FEMININO, APOIANDO INICIATIVAS QUE PROMOVAM O COOPERATIVISMO E AUTO SUSTENTAÇÃO','APOIAR POLÍTICAS PÚBLICAS PARA ATENDER MULHERES NEGRAS, DE POVOS INDÍGENAS, TRADICIONAIS E ITINERANTES'],
    image: "./images/modal/ods5.svg",
    color: "#ef402d"
}

export const ods6 = {
    h1Text: "6 - Água Potavel e Saniamento",
    mainText: "VOCÊ SABIA QUE A ÁGUA É MUITO IMPORTANTE PARA A SOBREVIVÊNCIA DE TODOS OS SERES VIVOS. E SABIA QUE O NOSSO PLANETA É QUASE TODINHO COBERTO DE ÁGUA? ISSO MESMO, 70% DO PLANETA É ÁGUA. MAS NEM TODA ESSA ÁGUA É PRÓPRIA PARA O CONSUMO. SOMENTE 2,5% DE TODA A ÁGUA É DOCE E A MAIORIA DELA SE ENCONTRA EM REGIÕES DE DIFÍCIL ACESSO, COMO GELEIRAS. ESTE OBJETIVO TAMBÉM TEM METAS SOBRE O SANEAMENTO BÁSICO E PROTEÇÃO DOS RIOS E NASCENTES. ISTO É, DIZ QUE É IMPORTANTE TER ESGOTO TRATADO, ACABAR COM A POLUIÇÃO DOS RIOS, DOS LAGOS E DE LAGOAS, QUE SÃO JUSTAMENTE DE ONDE SE TIRA A ÁGUA QUE VAI PARA AS TORNEIRAS DAS NOSSAS CASAS.",
    firstTopic: ['ECONOMIZE ÁGUA','NÃO ESCOVE OS DENTES COM A TORNEIRA ABERTA','NA HORA DO BANHO, EVITE DEIXAR O CHUVEIRO ABERTO ENQUANTO SE ENSABOA E LIGUE-O APENAS QUANDO FOR SE ENXAGUAR','FALE PARA OS SEUS PAIS PARA LAVAR A CALÇADA OU O CARRO COM BALDE','NÃO JOGUE LIXO NO CHÃO'],
    secondTopic: ['REPARAR VAZAMENTOS DE ÁGUA NAS RESIDÊNCIAS','COMUNICAR A EMPRESA RESPONSÁVEL PELO ABASTENCIMENTO DE ÁGUA SEMPRE QUE TIVER VAZAMENTO NAS RUAS.','IMPLANTAR PROGRAMAS DE RACIONALIZAÇÃO DO USO DA ÁGUA.','REPENSAR O USO: RACIONALIZAR SEMPRE QUE POSSÍVEL HÁBITOS DE CONSUMO NO COTIDIANO.','REAPROVEITAR A ÁGUA DA CHUVA','PROMOVER PERMANENTEMENTE DEBATE SOBRE O CONSUMO CONSCIENTE DA ÁGUA.','DISCUTIR COM A SOCIEDADE O USO DA ÁGUA NA AGRICULTURA, INDÚSTRIA E DEMAIS SETORES DA ECONOMIA.','PRESERVAR OU REVITALIZAR TODO O SISTEMA HÍDRICO (RIOS E NASCENTES) DA REGIÃO.'],
    image: "./images/modal/ods6.svg",
    color: "#27bfe6"
}

export const ods7 = {
    h1Text: "7 - Energia Limpa e Acessível",
    mainText: "SABIA QUE COMO A ÁGUA, A ENERGIA TAMBÉM É MUITO IMPORTANTE PARA A VIDA? E VOCÊ SABIA QUE EXISTEM VÁRIOS TIPOS DE FONTES DE ENERGIA? TEM AS DE FONTE NÃO RENOVÁVEL, COMO O PETRÓLEO, O CARVÃO MINERAL E O GÁS NATURAL. E AS DE FONTES RENOVÁVEIS, QUE SÃO AQUELAS GERADAS POR RECURSOS QUE NUNCA ACABAM, COMO A ÁGUA, O VENTO, O SOL E O MAR. E EXISTE TAMBÉM A ENERGIA GERADA POR BIOMASSA, QUE É PRODUZIDA PELA QUEIMA DE MATERIAIS COMO A CANA-DE-AÇÚCAR. SABIA QUE O ÁLCOOL QUE É USADO NOS CARROS É UM TIPO DE ENERGIA DE BIOMASSA?",
    firstTopic: ['SEMPRE APAGUE A LUZ QUANDO VOCÊ SAIR DE UM AMBIENTE.','DESLIGUE OS APARELHOS QUE NÃO ESTIVER USANDO.','PEÇA PARA SUA FAMÍLIA TROCAR AS LÂMPADAS DA SUA CASA POR LÂMPADAS QUE CONSOMEM MENOS ENERGIA, COMO AS DE LED.','NÃO TOME BANHO DEMORADO.','SÓ SOLTE PIPA EM LUGARES BEM LONGE DE POSTES E FIOS.'],
    secondTopic: ['COMPRAR ELETRODOMÉSTICOS E EQUIPAMENTOS MAIS EFICIENTES.','DESLIGAR O COMPUTADOR E OUTROS EQUIPAMENTOS QUE NÃO ESTIVER SENDO USADO.','MELHORAR A EFICIÊNCIA ENERGÉTICA DE EMPRESAS, RESIDÊNCIAS E ILUMINAÇÃO PÚBLICA.','DESENVOLVER LINHAS DE PESQUISAS EM TORNO DA UTILIZAÇÃO DE ENERGIAS RENOVÁVEIS E EFICIÊNCIA ENERGÉTICA.','ESTIMULAR O USO DE APLICATIVOS PARA MONITORAR E REDUZIR O CONSUMO DE ENERGIA.','FAZER CAMPANHAS DE ORIENTAÇÃO SOBRE O CONSUMO CONSCIENTE.','DIMENSIONAR CORRETAMENTE OS CABOS E FIOS PARA OS EQUIPAMENTOS.','ANALISAR O CONTRATO DE FORNECIMENTO COM A CONCESSIONÁRIA DE DISTRIBUIÇÃO.','USAR LÂMPADAS, LUMINÁRIAS E EQUIPAMENTOS AUXILIARES DE GRANDE EFICIÊNCIA LUMINOSA NA ILUMINÇÃO PÚBLICA.','UTILIZAR EQUIPAMENTOS EFICIENTES (LÂMPADAS, EQUIPAMENTOS DE AR CONDICIONADO, MOTORES, ETC) NOS PRÉDIOS PÚBLICOS.','PROMOVER DEBATE NAS ESCOLAS E COMUNIDADES SOBRE O CONSUMO CONSCIENTE.'],
    image: "./images/modal/ods7.svg",
    color: "#fbc412"
}

export const ods8 = {
    h1Text: "8 - Trabalho Decente e Crescimento Econômico",
    mainText: "O CRESCIMENTO ECONÔMICO É IMPORTANTE PARA GERAR RIQUEZAS PARA OS PAÍSES E PARA AS PESSOAS. MAS ELE PRECISA SER SUSTENTÁVEL! ISTO É, PRECISA PRESERVAR A NATUREZA E CUIDAR DAS PESSOAS PARA QUE TODOS TENHAM AS MESMAS OPORTUNIDADES. DURANTE MUITOS ANOS, AS PESSOAS ACREDITAVAM QUE O QUE TEM NA NATUREZA NUNCA IRIA ACABAR. MAS, JÁ APRENDEMOS QUE NÃO É VERDADE. SE CADA UM FIZER A SUA PARTE, VAMOS TER A POSSIBILIDADE DE AUMENTAR A RENDA DAS FAMÍLIAS, DIMINUIR O DESEMPREGO, PRESERVAR A NATUREZA E GARANTIR UM FUTURO MELHOR PARA TODOS",
    firstTopic: ['ECONOMIZAR SEU DINHEIRO','CONVERSE COM SEUS PAIS SOBRE AS COMPRAS DA CASA, PEÇA PARA TE ENSINAREM SOBRE OS VALORES DAS COISAS.','QUANDO VOCÊ OU SEUS PAIS FOREM COMPRAR ALGUM PRESENTE, COMPRE DE PRODUTORES OU EM LOJAS PERTO DA SUA CASA.','E MUITO IMPORTANTE: CRIANÇA NÃO DEVE TRABALHAR, ELA DEVE BRINCAR E ESTUDAR. MAS, ELA PODE AJUDAR COM AS TAREFAS DE CASA. COMO ARRUMAR O SEU QUARTO, POR EXEMPLO!'],
    secondTopic: ['REALIZAR CURSOS À DISTÂNCIA DE APERFEIÇOAMENTO PROFISSIONAL','GERAR EMPREGOS DECENTES','AUMENTAR A PRODUTIVIDADE INDIVIDUAL','AUMENTAR A CONFIANÇA ENTRE OS MORADORES DO MUNICÍPIO','PROMOVER A EDUCAÇÃO PARA O EMPREENDEDORISMO','CRIAR EMPRESAS JUNIORES','CRIAR BOLSAS DE ESTÁGIOS PARA OS ACADÊMICOS','ESTIMULAR O USO DE APLICATIVOS COMO WHATSAPP PARA DIVULGAÇÃO DE VAGAS','AUMENTAR A PRODUTIVIDADE DAS EMPRESAS','INCENTIVAR A CRIAÇÃO DE STARTUPS EM ÁREAS COMPETITIVAS','DESENVOLVER A CULTURA DE EMPREENDORISMO NAS UNIVERSIDADES E ESCOLAS','INCLUIR A PESSOA COM DEFICIÊNCIA NO MERCADO DE TRABALHO.','APOIAR PROGRAMAS DE GERAÇÃO DE NOVAS OPORTUNIDADES DE ABSORÇÃO E RECRUTAMENTOS DE JOVENS NAS PEQUENAS E MÉDIAS EMPRESAS.','PROMOVER A EDUCAÇÃO FINANCEIRA PARA REDUZIR O ENDIVIDAMENTO DA POPULAÇÃO.','PROTEGER OS DIREITOS TRABALHISTAS E PROMOVER AMBIENTES DE TRABALHO SEGUROS E PROTEGIDOS PARA TODOS OS TRABALHADORES.'],
    image: "./images/modal/ods8.svg",
    color: "#a31c44"
}

export const ods9 = {
    h1Text: "9 - Industria Inovação e Infraestrutura",
    mainText: "SABIA QUE ESTA CARTILHA QUE VOCÊ ESTÁ LENDO PRECISOU DE UMA INDÚSTRIA, DE INOVAÇÃO E DE INFRAESTRUTURA? A VERSÃO IMPRESSA FOI FEITA NUMA GRÁFICA, QUE PRECISOU DE PAPEL, QUE POR SUA VEZ É PRODUZIDO NUMA INDÚSTRIA. TODAS AS PALAVRAS E DESENHOS PRECISARAM DE UM COMPUTADOR PARA SEREM COLOCADOS NA CARTILHA E ESSE COMPUTADOR FOI UMA INOVAÇÃO. A INOVAÇÃO ACONTECE QUANDO ALGUÉM CRIA UMA COISA NOVA, QUE NUNCA EXISTIU, OU PEGA UMA COISA QUE JÁ EXISTE E TRANSFORMA EM ALGO MELHOR. PARA ELA CHEGAR ATÉ A SUA MÃO, FOI NECESSÁRIO TER INFRAESTRUTURA, SEJA ATRAVÉS DA INTERNET NESSA VERSÃO DIGITAL OU PELAS TRANSPORTADORAS, NA VERSÃO IMPRESSA. MAS, AS INDÚSTRIAS PRECISAM PRESERVAR O MEIO AMBIENTE E GERAR EMPREGOS. A INOVAÇÃO E A INFRAETRUTURA PRECISAM BENEFICIAR TODOS OS CIDADÃOS PARA QUE O MUNDO SEJA SUSTENTÁVEL.",
    firstTopic: ['FAÇA PESQUISAS SOBRE TECNOLOGIA E INOVAÇÃO.','PARTICIPE DAS FEIRAS DE CIÊNCIAS E INOVAÇÃO NA SUA ESCOLA','PESQUISE SE AS INDÚSTRIAS PERTO DA SUA CASA ESTÃO CUIDANDO DO MEIO AMBIENTE.','OBSERVE COMO ESTÃO AS ESTRADAS, PONTES E VIADUTOS DA SUA CIDADE E REGIÃO.','DESENVOLVA A SUA CRIATIVIDADE.'],
    secondTopic: ['PROMOVER GINCANAS, FEIRAS DE CIÊNCIA E INOVAÇÃO','APOIAR PROGRAMAS DE PARCEIRAS PARA A INCLUSÃO DIGITAL DA POPULAÇÃO MENOS FAVORECIDA','OFERECER EDITAIS DE INOVAÇÃO','OFERECER PROGRAMAS DE FORMAÇÃO E DISSEMINAÇÃO DAS NOVAS TECNOLOGIAS EM ESPECIAL DA INFORMAÇÃO, QUE PROMOVAM TAMBÉM A INCLUSÃO DE PESSOAS COM DEFICIÊNCIA.','INOVAR OS PROCESSOS DAS EMPRESAS.','ADOTAR LEAN MANUFACTURING NA EMPRESA','REDUZIR OS DESPERDÍCIOS NO PROCESSO PRODUTIVO','AUMENTAR O TEMPO DE VIDA DOS PRODUTOS','INVESTIR NA INFRAESTRUTURA DO MUNICÍPIO','BUSCAR O INVESTIMENTO DA INICIATIVA PRIVADA EM INFRAESTRUTURA','REALIZAR CAMPANHAS DO MELHOR USO DE NOSSAS ESTRADAS, PARQUES, ACADEMIAS AO AR LIVRE E DEMAIS INSTALAÇÕES DE EQUIPAMENTOS PÚBLICOS'],
    image: "./images/modal/ods9.svg",
    color: "#f26a2e"
}

export const ods10 = {
    h1Text: "10 - Redução das Desigualdades",
    mainText: "VOCÊ SABE O QUE É DESIGUALDADE? VAMOS FAZER DE CONTA QUE TODA A POPULAÇÃO DA SUA CIDADE SÃO 10 PESSOAS. 1 PESSOA REPRESENTA A POPULAÇÃO MAIS RICA E UMA OUTRA PESSOA REPRESENTA A POPULAÇÃO MAIS POBRE. A DESIGUALDADE EXISTE PORQUE A PESSOA MAIS RICA TEM SOZINHA PRATICAMENTE TODA A RIQUEZA SOMADA DAS OUTRAS. E SABIA QUE A DESIGUALDADE NÃO É SÓ ENTRE AS PESSOAS? É ENTRE OS PAÍSES TAMBÉM. EXISTEM POUCOS PAÍSES MUITO RICOS E MUITOS PAÍSES MUITO POBRES. REDUZIR AS DESIGUALDADES IRÁ TIRAR MUITAS PESSOAS DA POBREZA E MELHORAR AS CONDIÇÕES DE VIDA DE TODOS.",
    firstTopic: ['TRATE AS PESSOAS COM RESPEITO','DOE SUAS ROUPAS E BRINQUEDOS QUE NÃO USA MAIS','CONVERSE COM SUA FAMÍLIA PARA AJUDAR AS PESSOAS MAIS POBRES'],
    secondTopic: ['ENSINAR SOBRE CIDADANIA E SOLIDARIEDADE PARA TODOS.','DOAR ALIMENTOS, ROUPAS E OUTROS BENS PARA A POPULAÇÃO POBRE.','DESTINAR IMPOSTO DEVIDO PARA PROJETOS SOCIAIS.','GARANTIR A IGUALDADE DE OPORTUNIDADE PARA AS PESSOAS.','PROMOVER O VOLUNTARIADO CORPORATIVO.','IMPLANTAR PROGRAMAS DE CARREIRAS E SALÁRIO JUSTOS.','PATROCINAR A EDUCAÇÃO DE JOVENS MENOS FAVORECIDOS ECONOMICAMENTE.','AUMENTAR O INVESTIMENTO SOCIAL PRIVADO.','PARTICIPAR COMO VOLUNTARIADO EM PROJETOS SOCIAIS.','DESENVOLVER PESQUISAS E ESTUDOS SOBRE A REALIDADE SOCIAL.','INVESTIR NA INFRAESTRUTURA DAS COMUNIDADES MENOS FAVORECIDAS.','CRIAR POLÍTICAS E PROGRAMAS QUE VISEM A REDUÇÃO DAS DESIGUALDADES. ','PROMOVER OS DIREITOS DOS IMIGRANTES E DE OUTRAS MINORIAS'],
    image: "./images/modal/ods10.svg",
    color: "#de1768"
}

export const ods11 = {
    h1Text: "11 - Cidades e Comunidades Sustentáveis",
    mainText: "PARA QUE AS CIDADES SEJAM SUSTENTÁVEIS, A AGENDA 2030 DEFENDE QUE AS PESSOAS VIVAM EM LUGARES SEGUROS E COM ACESSO A SERVIÇOS BÁSICOS, NO MÍNIMO! AS CIDADES PRECISAM TER PRAÇAS E PARQUES PARA QUE TODOS POSSAM SE DIVERTIR E TER ABASTECIMENTO DE ÁGUA E ENERGIA EM TODAS AS CASAS. DE PREFERÊNCIA ENERGIAS DE FONTES RENOVÁVEIS. COLETA DE LIXO E TRATAMENTO DO ESGOTO TAMBÉM SÃO FUNDAMENTAIS. PRECISAM TER TRANSPORTE SEGURO E QUE NÃO POLUA O MEIO AMBIENTE. TER ESPAÇOS PARA BICICLETAS E PATINETES É UMA EXCELENTE IDEIA! É MUITO IMPORTANTE TAMBÉM PRESERVAR A HISTÓRIA DA CIDADE, SEUS MONUMENTOS, SUA CULTURA E FOLCLORE. SEM ESQUECER DE PRESERVAR OS RECURSOS NATURAIS. AS CIDADES SUSTENTÁVEIS TÊM MUITO VERDE PARA TODOS OS LADOS, QUE AJUDA A DESPOLUIR O AR.",
    firstTopic: ['ANDE DE BICICLETA, OU SKATE OU PATINETE. MAS, NÃO SE ESQUEÇA DE USAR CAPACETE E PROTEGER SEUS JOELHOS E COTOVELOS.','SE TIVER UMA PRAÇA PERTO DA SUA CASA, BRINQUE NELA SEMPRE QUE PUDER.','SE A PRAÇA NÃO ESTIVER LIMPA OU NÃO TIVER BRINQUEDOS, CONVERSE COM SEUS VIZINHOS PARA JUNTOS LIMPAREM A PRAÇA E SE POSSÍVEL CONSERTAR OS BRINQUEDOS.','SE EXISTIR, VISITE OS MUSEUS DA SUA CIDADE OU PRÓXIMAS.','PREFIRA PASSEAR DE TRANSPORTE PÚBLICO E COLETIVO'],
    secondTopic: ['PARTICIPAR DE CONSELHOS DE SEGURANÇA NAS CIDADES','PROTEGER O PATRIMÔNIO CULTURAL DO MUNICÍPIO.','REDUZIR O USO DE AUTOMÓVEL, PEGAR CARONA SOLIDÁRIA, BICICLETA E OUTROS MEIOS DE TRANSPORTES','PROMOVER A CRIAÇÃO OU A MANUTENÇÃO DAS ASSOCIAÇÕES DE BAIRROS','REALIZAR CAMPANHAS NAS ESCOLAS E UNIVERSIDADES SOBRE TRÂNSITO SEGURO.','APOIAR INICIATIVAS NA IMPLEMENTAÇÃO DE PRÁTICAS AMBIENTAIS SUSTENTÁVEIS.','DISSEMINAR O ESTATUTO DAS CIDADES E O PLANO DIRETOR DO MUNICÍPIO.','AUMENTAR A ACESSIBILIDADE DAS PESSOAS AOS EQUIPAMENTOS PÚBLICOS.','PROMOVER A CIDADANIA NAS COMUNIDADES.','AUMENTAR A DESTINAÇÃO DE IMPOSTOS PARA PROJETOS SOCIAIS.','DESENVOLVER PROGRAMAS PARA A MELHORIA DA MOBILIDADE URBANA.','DESENVOLVER PROJETOS VISANDO A REGULARIZAÇÃO FUNDIÁRIA','MELHORAR A INFRAESTRUTURA DAS REGIÕES METROPOLITANAS','ESTRUTURAR PROGRAMAS QUE MANTENHAM O HOMEM NO CAMPO.'],
    image: "./images/modal/ods11.svg",
    color: "#f89d2a"
}

export const ods12 = {
    h1Text: "12 - Consumo e Produção Responsaveis",
    mainText: "VOCÊ SABE O QUE É CONSUMO CONSCIENTE? É USAR TUDO O QUE PRECISAMOS SEM DESPERDIÇAR OU DE FORMA QUE NÃO PRODUZA MAIS LIXO. É CONSUMIR SOMENTE AQUILO QUE REALMENTE PRECISAMOS. ISSO É MUITO SÉRIO. SE AS PESSOAS NÃO MUDAREM URGENTEMENTE O SEU JEITO DE CONSUMIR AS COISAS, NO ANO DE 2050 VAMOS PRECISAR ARRANJAR MAIS 2 PLANETAS IGUALZINHOS AO NOSSO PARA CONTINUARMOS A TER ALIMENTOS, ÁGUA, ENERGIA, ROUPAS E TUDO MAIS QUE FOR PRECISO PARA A NOSSA VIDA. VOCÊ SABE QUE NÃO EXISTE MAIS DOIS PLANETAS, ENTÃO O QUE É PRECISO FAZER PARA ISSO NÃO ACONTECER? PODEMOS COMEÇAR USANDO OS 3 R’S: REDUZIR, REUTILIZAR E RECICLAR.",
    firstTopic: ['REDUZA A QUANTIDADE DE LIXO QUE VOCÊ GERA.','SEPARE O LIXO PARA RECICLAGEM.','NÃO DESPERDICE COMIDA, ÁGUA E ENERGIA.','REUTILIZE POTES E GARRAFAS, VOCÊ PODE USAR PARA GUARDAR COISAS OU ATÉ MESMO COMO VASOS.','PENSE MUITO ANTES DE COMPRAR OU PEDIR PARA SEUS PAIS COMPRAR ALGO. PENSE SE VOCÊ REALMENTE PRECISA DISSO.','COMPARTILHE COM SEUS AMIGOS OS LIVROS QUE VOCÊ JÁ LEU OU OS BRINQUEDOS QUE JÁ NÃO QUER BRINCAR MAIS.','APROVEITE CASCAS DE FRUTAS E LEGUMES COMO ADUBO PARA AS PLANTAS'],
    secondTopic: ['DOAR ALIMENTOS E REDUZIR O DESPERDÍCIO.','REDUZIR O USO DE EMBALAGENS.','REAPROVEITAR ALIMENTOS O MÁXIMO QUE FOR POSSÍVEL.','DESCARTAR OS RESÍDUOS DE FORMA CORRETA.','PROMOVER OFICINAS DE APROVEITAMENTO DE MATERIAIS PARA CONFECÇÃO DE ARTESANATO.','PARTICIPAR DE PROGRAMAS DE MOBILIZAÇÃO COLETIVA PARA ESTÍMULO À RECICLAGEM E REUTILIZAÇÃO DE MATERIAIS.','REALIZAR AÇÕES DE SENSIBILIZAÇÃO PARA A DIMINUIÇÃO DO CONSUMO E SEPARAÇÃO DE RESÍDUOS (3RS – REDUZIR, REUTILIZAR E RECICLAR).','IMPLANTAR A LOGÍSTICA REVERSA NAS EMPRESAS.','CONHECER E RESPEITAR O CÓDIGO DO CONSUMIDOR.','IMPLEMENTAR A COLETA SELETIVA NOS MUNICÍPIOS'],
    image: "./images/modal/ods12.svg",
    color: "#bf8d2c"
}

export const ods13 = {
    h1Text: "13 - Ação Contra a Mudança Global do Clima",
    mainText: "VOCÊ SABE O QUE É AQUECIMENTO GLOBAL? VAMOS EXPLICAR: EM VOLTA DO NOSSO PLANETA EXISTE UMA CAMADA DE GAZES QUE PROTEGEM O PLANETA MANTENDO A TEMPERATUDA IDEAL PARA A VIDA. FUNCIONANDO COMO UMA ESTUFA! COM A POLUIÇÃO, ESSA CAMADA ESTÁ FICANDO CADA VEZ MAIS GROSSA E AUMENTANDO A TEMPERATUA MÉDIA DO PLANETA. SABE O QUE PODE ACONTECER? O AUMENTO DA TEMPERATURA PODE PROVOCAR MUDANÇAS CLIMÁTICAS. ISTO É, TEREMOS VERÕES CADA VEZ MAIS QUENTES E INVERNOS CADA VEZ MAIS FRIOS. MAIS OCORRENCIAS DE FURACÕES, ENCHENTES E SECAS. AS GELEIRAS PODEM DERRETER E AUMENTAR O NÍVEL DO MAR.",
    firstTopic: ['CAMINHE E ANDE DE BICICLETA','NÃO JOGUE LIXO NAS RUAS','COMA MAIS FRUTAS, VERDURAS E LEGUMES E COMA MENOS CARNE','ESTUDE SOBRE ENERGIAS RENOVÁVEIS E NÃO POLUENTES','CONTE PARA SEUS PAIS, FAMILIARES E ATÉ MESMO PARA O MOTORISTA DO ONIBUS DA SUA ESCOLA, QUE É MUITO IMPORTANTE MANTER O MOTOR DO CARRO OU DO ÔNIBUS BEM REGULADO PARA NÃO POLUIR O AR.','PLANTE ÁRVORES E NÃO DEIXE DERRUBAREM AS ÁRVORES NA SUA CIDADE E NEM NAS FLORESTAS.'],
    secondTopic: ['PLANTAR ÁRVORES NAS ÁREAS RURAIS E URBANAS','ENSINAR AS LEIS AMBIENTAIS NAS ESCOLAS E UNIVERSIDADES','REALIZAR CAMPANHAS CONTRA AS QUEIMADAS E DESMATAMENTO','DAR SUPORTE A PROJETOS DE PESQUISA E FORMAÇÃO NA ÁREA AMBIENTAL','REALIZAR O CONTROLE DA EMISSÃO DE GÁS CARBÔNICO, METANO E ÓXIDO NITROSO, E OUTROS GASES POLUENTES','MANTER A FROTA DE VEÍCULOS COM A MANUTENÇÃO EM DIA','DIVULGAR INFORMAÇÕES SOBRE GESTÃO DE RISCO SOBRE DESASTRES E ADAPTAÇÕES A MUDANÇAS CLIMÁTICAS.','PROTEGER AS ÁREAS VERDES','CRIAR LINHAS DE PESQUISAS PARA O DESENVOLVIMENTO DE PROCESSOS E PRODUTOS QUE MINIMIZEM AS MUDANÇAS CLIMÁTICAS.'],
    image: "./images/modal/ods13.svg",
    color: "#407f46"
}

export const ods14 = {
    h1Text: "14 - Vida na Água",
    mainText: "OS OCEANOS SÃO FONTE DE VIDA. NELES VIVEM MILHARES DE ANIMAIS E PLANTAS QUE SÃO MUITO IMPORTANTES PARA O NOSSO ECOSSISTEMA. SABIA QUE O LIXO JOGADO NOS RIOS VAI PARAR NO MAR? E ESSE LIXO PODE MATAR OS PEIXES, TARTARUGAS E TODOS OS SERES QUE VIVEM NELE? ISSO MESMO! OS ANIMAIS MARINHOS NÃO SABEM VER A DIFERENÇA ENTRE UMA SACOLA DE PLÁSTICO DE UMA ÁGUA-VIVA. PARA GARANTIR A VIDA NA ÁGUA, DEVEMOS LEMBRAR SEMPRE QUE TODO O LIXODESCARTADO DE FORMA INCORRETA ACABA NA ÁGUA. PORTANTO, DIZER NÃO A TUDO QUE É DESCARTÁVEL É MUITO IMPORTANTE.",
    firstTopic: ['NÃO USE COPOS, PRATOS E TALHERES DE PLÁSTICO DESCARTÁVEL','NÃO DEIXE LIXO NA PRAIA','SE TIVER UM RIO PERTO DA SUA ESCOLA, CONVERSE COM SEUS PROFESSORES PARA CONHECER E PROTEGER O RIO','CONVIDE SEUS AMIGOS E COLEGAS PARA FAZER UM MUTIRÃO DE LIMPEZA NA SUA RUA OU ESCOLA, NÃO ESQUEÇA DE PEDIR AJUDA PARA UM ADULTO.'],
    secondTopic: ['IMPLANTAR REDE DE COLETA E ESTAÇÃO DE TRATAMENTO DE ESGOTO PARA O MUNICÍPIO.','RESPEITAR O PERÍODO DE DEFESO E NÃO PRATICAR A PESCA PREDATÓRIA.','FAZER CAMPANHAS DE PROTEÇÃO DOS RIOS E REFLORESTAMENTO DAS MATAS CILIARES ','DENUNCIAR PESOSAS/EMPRESAS QUE JOGAM LIXOS NOS RIOS E LOCAIS IRREGULARES','MELHORAR O SANEAMENTO DAS CIDADES','INCENTIVAR O REAPROVEITAMENTO DE ÓLEO DE COZINHA '],
    image: "./images/modal/ods14.svg",
    color: "#1f97d4"
}

export const ods15 = {
    h1Text: "15 - Vida Terrestre",
    mainText: "BIODIVERSIDADE É O CONJUNTO DE TODAS AS FORMAS DE VIDA EM TODOS OS CANTOS DA TERRA. VOCÊ SABIA QUE QUASE TODOS OS INSETOS, PLANTAS E ANIMAIS TERRESTRES VIVEM NAS FLORESTAS? AS FLORESTAS SÃO MUITO IMPORTANTES PARA O ECOSSISTEMA TERRESTRE. MESMO ASSIM, O DESMATAMENTO CONTINUA ACONTECENDO E ISSO AUMENTA O RISCO DE EXTINÇÃO DOS ANIMAIS E PLANTAS. SABIA QUE SOMOS A PRIMEIRA GERAÇÃO QUE APRENDEU MAIS SOBRE O VALOR DA NATUREZA E TAMBÉM PODEMOS SER A ÚLTIMA QUE PODE SALVAR O MUNDO?",
    firstTopic: ['PESQUISE SOBRE AS ESPÉCIES DE ANIMAIS E PLANTAS AMEAÇADOS DE EXTINÇÃO','ESTUDE SOBRE A DIFERENÇA DE ANIMAIS SILVESTRES E ANIMAIS DOMÉSTICOS','CONVIDE SEUS AMIGOS E PROFESSORES PARA PLANTAR ÁRVORES NA SUA ESCOLA OU NUM PARQUE','NÃO ACEITE E NÃO DEIXE ALGUÉM TER ANIMAIS SILVESTRES COMO SE FOSSEM ANIMAIS DE ESTIMAÇÃO','REDUZA O USO DE PAPEL. SABIA QUE O PAPEL É FEITO DAS ÁRVORES?','PEÇA PARA SEU PROFESSOR AJUDAR VOCÊ A PESQUISAR SOBRE PEGADA ECOLÓGICA'],
    secondTopic: ['CONHECER E REDUZIR A PEGADA ECOLÓGICA INDIVIDUAL','PLANTAR ÁRVORES','NÃO COMPRE ANIMAIS SILVESTRES E DENUNCIE QUEM O FAZ','REALIZAR CAMPANHAS SOBRE A IMPORTÂNCIA DA BIODIVERSIDADE E SUA PRESERVAÇÃO','REDUZIR O CONSUMO DE PAPEL','VALORIZAR OS SABERES LOCAIS E AS COMUNIDADES INDÍGENAS','REDUZIR O USO DE AGROTÓXICOS NAS LAVOURAS','INCENTIVAR A CULTURA DE ROTAÇÃO NO PLANTIO AGRÍCOLA','IMPLANTAR PROJETOS PARA REDUÇÃO DA DESERTIFICAÇÃO E A DEGRADAÇÃO DOS SOLOS','REDUZIR AS ESPÉCIES INVASORAS E EXÓTICAS','REDUZIR O DESMATAMENTO','REDUZIR A GERAÇÃO DE RESÍDUOS'],
    image: "./images/modal/ods15.svg",
    color: "#59ba47"
}

export const ods16 = {
    h1Text: "16 - Paz, Justiça e Instituições eficazes",
    mainText: "VOCÊ SABIA QUE AS BRIGAS COMEÇAM PORQUE AS PESSOAS NÃO SE ENTENDEM OU NÃO GOSTAM DE QUEM TEM IDEIAS DIFERENTES? ALGUMAS PESSOAS FICAM FAZENDO ALGUMAS BRINCADEIRAS CHATAS COM AS OUTRAS SÓ PORQUE SÃO DIFERENTES, MAS NÓS SABEMOS QUE SER DIFERENTE É MUITO LEGAL. E QUANDO ESSAS PESSOAS SÃO LÍDERES DE PAÍSES E NÃO RESPEITAM COMO OUTROS PAÍSES CUIDAM DO SEU POVO, ELAS BRIGAM E SURGEM AS GUERRAS. JÁ IMAGINOU QUE CHATO SERIA SE TODO MUNDO FOSSE IGUAL? POR EXEMPLO, SE TODO MUNDO TORCESSE PARA O MESMO TIME, NÃO EXISTIRIAM OS CAMPEONATOS COMO A COPA DO MUNDO E AS OLIMPÍADAS. POR ISSO PRECISAMOS SEMPRE ACEITAR E RESPEITAR AS DIFERENÇAS PARA VIVERMOS NUM MUNDO EM PAZ!",
    firstTopic: ['RESPEITE AS PESSOAS QUE PENSAM E ACREDITAM EM COISAS DIFERENTES DAS SUAS','SE ALGUÉM TE ABORRECER, NÃO BRIGUE. TENTE CONVERSAR COM QUEM TE ABORRECEU OU CONTE PARA OS SEUS PAIS OU PROFESSORES','SE VOCÊ VER ALGUÉM FAZENDO CHACOTA OU AMEAÇANDO UM AMIGO OU COLEGA, CONVERSE COM OS SEUS PAIS E PROFESSORES','FIQUE LONGE DAS DROGAS, A MAIORIA DAS MORTES VIOLENTAS ESTÃO DIRETAMENTE LIGADAS AO USO DE DROGAS.'],
    secondTopic: ['REALIZAR PALESTRA SOBRE RELACIONAMENTOS, PREVENÇÃO A VIOLÊNCIA E CUIDADOS NO TRÂNSITO.','PARTICIPAR DE ESPAÇOS COMO CONSELHO TUTELAR E CONSELHOS DE DIREITOS NO SEU MUNICÍPIO','REALIZAR CAMPANHAS DE CULTURA DA PAZ, SENSIBILIZAÇÃO E COMBATE Á VIOLÊNCIA DOMÉSTICA, ABUSO SEXUAL E TRÁFICO HUMANO','DENUNCIAR CASOS DE VIOLÊNCIA CONTRA A MULHER, IDOSOS E CRIANÇAS','COMBATER A CORRUPÇÃO EM TODOS OS NÍVEIS','PROMOVER O ACESSO A ESPAÇO CULTURAL E ARTÍSTICO PARA A POPULAÇÃO JOVEM.','APOIAR O FUNCIONAMENTO OU INSTALAÇÃO DE UM CENTRO DE AÇÃO VOLUNTÁRIA NA SUA CIDADE.','FORTALECER AS INSTITUIÇÕES EFICAZES, RESPONSÁVEIS E TRANSPARENTES ','IMPLANTAR SISTEMAS DE MONITORAMENTO DOS GASTOS E INVESTIMENTOS PÚBLICOS, COMO OS PORTAIS DA TRANSPARÊNCIA E OBSERVATÓRIOS SOCIAIS','COMBATER O CRIME ORGANIZADO E REDUZIR O TRÁFICO DE ARMAS ILEGAIS.','PROMOVER E FAZER CUMPRIR LEIS E POLÍTICAS NÃO DISCRIMINATÓRIAS PARA O DESENVOLVIMENTO SUSTENTÁVEL'],
    image: "./images/modal/ods16.svg",
    color: "#136a9f"
}

export const ods17 = {
    h1Text: "17 - Parcerias e Meios de Implementação",
    mainText: "VOCÊ SABE O QUE É NECESSÁRIO PARA QUE TODOS ESSES ODS QUE VOCÊ APRENDEU ATÉ AQUI ACONTEÇAM? É NECESSÁRIO HAVER PARCERIAS! PORQUE É MUITO DIFÍCIL SER SUSTENTÁVEL SOZINHO, MAS COM AJUDA DOS AMIGOS, FAMÍLIA, ESCOLAS, EMPRESAS E GOVERNOS NÃO É IMPOSSÍVEL! CONVERSE COM SEUS AMIGOS, PROFESSORES E FAMILIARES E FORMEM UM TIME DE GUARDIÕES DO PLANETA!",
    firstTopic: ['AGORA QUE JÁ APRENDEU, ENSINE PARA AS PESSOAS O QUE VOCÊ CONHECE SOBRE OS OBJETIVOS DE DESENVOLVIMENTO SUSTENTÁVEL','COMPARTILHE AS COISAS BOAS QUE VOCÊ DESCOBRIR QUE AJUDAM A TORNAR O MUNDO UM LUGAR MELHOR PARA TODOS.','SEJA VOLUNTÁRIO'],
    secondTopic: ['PARTICIPAR DE ASSOCIAÇOES, CONSELHOS E ORGANIZAÇÕES SOCIAS','DISSEMINAR TECNOLOGIAS AMBIENTALMENTE CORRETAS','ELABORAR PROJETOS SOCIAS E BUSCAR FONTES DE FINANCIAMENTO COLETIVO','INCENTIVAR E PROMOVER PARCERIAS PÚBLICAS, PÚBLICO-PRIVADA, PRIVADAS PARA MOBILIZAÇÃO DE RECURSOS','ADOTAR OS PRINCÍPIOS DA GESTÃO EMPRESARIAL RESPONSÁVEL','CRIAR BANCOS DE PROJETOS SOCIAS E LINHAS DE FOMENTOS PARA ATUAR EM ÁREAS MAIS POBRES','PARTICIPAR DO MOVIMENTO NACIONAL ODS DO SEU ESTADO','REALIZAR PROGRAMAS DE RESPONSABILIDADE SOCIAL CORPORATIVA','BUSCAR REALIZAR PARCERIAS COM VÁRIOS ATORES SOCIAIS','BUSCAR RECURSOS PARA CAPACITAÇÃO, TECNOLOGIA E OUTROS INVESTIMENTOS PARA O DESENVOLVIMENTOS LOCAL SUTENTÁVEL','REDUZIR A DÍVIDA DOS MUNICÍPIOS','DISSEMINAR OS ODS NAS EMPRESAS, COMUNIDADE E PODER PÚBLICO'],
    image: "./images/modal/ods17.svg",
    color: "#14496b"
}