import React from 'react'
import VoceSabiaComponent from '../components/VoceSabia';

const VoceSabia = () => {

    return (
        <>
            <VoceSabiaComponent />
        </>
    )
}

export default VoceSabia