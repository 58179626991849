import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const FooterContainer = styled.footer`
    background-color: #fc6924;
`

export const Row  = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Col  = styled.div`
    flex: 32.33%;
    padding: 5px;
`



export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const ImgWrap = styled.div`
    max-width: 656px;
    height: 100%;
`
export const ImgFooter = styled.img`
margin 0 auto;
cursor: pointer;

  width: 158px;
`

export const Img = styled.img`
    width: 100%; 
    margin: 0 0 10px 0;
    padding-right: 0;
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const FooterLinkItemsDois = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 380px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px){
        margin:0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkItems = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px){
        margin:0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    align-self: center;
    font-size: 18px;
    margin-bottom: 16px;
`

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`

export const ContactWrap = styled.div`
    max-width: 656px;
`

export const Contact = styled.p`
    max-width: 448px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;

    @media screen and (max-width: 480px){
        font-size: 13px;
    }
`


