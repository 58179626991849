import styled from 'styled-components'

export const InfoContainer = styled.div`
    height: auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #b4deec;
`

export const InfoWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        //padding: 0 20px;
    }
`

export const InfoCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    border: 2px solid;
    height: 490px;
    min-width: 250px;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px){
        height: auto;
    }
`

export const SubContainer = styled.div`
    max-width: 75%;
    margin-top: ${props => props.marginTop || '0'};
    margin-bottom: ${props => props.marginBottom || '0'};

    @media screen and (max-width: 480px){
        max-width: 90%;
    }
`

export const InfoH1 = styled.h1`
    font-size: 2.5rem;
    //color: #fff;
    margin-bottom: 14px;

    @media screen and (max-width: 480px){
        //font-size: 2rem;
    }
`

export const InfoH4 = styled.h4`
    font-size: 1.1rem;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 28px;

    @media screen and (max-width: 480px){
        //font-size: 2rem;
    }
`

export const InfoH3 = styled.h2`
    font-size: 1.8em;
    text-align: left;
    padding-top: 20px;
    margin-bottom: 10px
`


export const InfoP = styled.p`
    font-size: 1rem;
    font-weight: ${props => props.title || '400'};
    text-decoration: ${props => props.underline || 'none'};
    padding-left: ${props => props.padding || '4px'};
    padding-top: ${props => props.top || '0px'};
    margin-bottom: ${props => props.marginBotton || '8px'};

    span{
        font-weight: 600;
    }
`