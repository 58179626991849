import React from 'react'
import HomeSection from '../components/HomeSection';

const Home = () => {
    return (
        <>
            <HomeSection />
        </>
    )
}

export default Home
