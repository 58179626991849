import React, {useState} from 'react'

import icon1 from './img/Jogo-background-laranja-claro.png'
import icon2 from './img/Jogo-background-laranja-escuro.png'
import icon3 from './img/Jogo-background-vermelho.png'

import { 
    JogosContainer,
    JogosH1,
    JogosWrapper,
    JogosCard,
    JogosIcon,
    JogosH2
} from './JogosElements'

import Memoria from './Games/memoria/index'
import Quiz from './Games/Quiz/index'
import PuzzleGame from './Games/QuebraCabeca/index'
import JogoVelha from './Games/jogo-da-velha/index'
import CacaPalavras from './Games/caca-palavras'
import Crossword from './Games/Crossword'


import {BackModal,BodyModal,CloseModalButton,ContainerCloseModalButton,ContentModalBody} from './jogosModalElements'

const Jogos = () => {

    const [Abremodal, setAbreModal] = useState(false)
    const [tela, setTela] = useState(0)
    let widthModal = "90%";
    if(tela === 2){
        widthModal = "55%";
    }
     

    return (
        <>
            
            {Abremodal ?
                <BackModal>
                    <BodyModal width={widthModal} height={(tela === 2 && window.innerWidth < 700) ? '64%' : '90%'}>
                        <ContainerCloseModalButton>
                            <CloseModalButton onClick={() => {setAbreModal(false)}}/>
                        </ContainerCloseModalButton>
                        <ContentModalBody >
                            {tela === 0 ? <Memoria/> : null}
                            {tela === 1 ? <Quiz/> : null}
                            {tela === 2 ? <PuzzleGame/> : null}
                            {tela === 3 ? <CacaPalavras/> : null}
                        </ContentModalBody>
                    </BodyModal>
                </BackModal>
            : null}


            <JogosContainer>
                <JogosH1>Jogos</JogosH1>
                <JogosWrapper>
                    <JogosCard style={{color: "white",backgroundColor: "red"}} onClick={() => {setTela(0);setAbreModal(true);}}>
                        <JogosIcon src={icon2}/>
                        <JogosH2>Jogo da Memória</JogosH2>
                    </JogosCard>
                    <JogosCard  style={{color: "white",backgroundColor: "#A21943"}}   onClick={() => {setTela(1);setAbreModal(true);}}>
                        <JogosIcon src={icon1}/>
                        <JogosH2>Quiz</JogosH2>
                    </JogosCard>
                </JogosWrapper>
                <JogosWrapper>
                    <JogosCard style={{color: "white",backgroundColor: "#DDA939"}} onClick={() => {setTela(2);setAbreModal(true);}}>
                        <JogosIcon src={icon3}/>
                        <JogosH2>Quebra-cabeça</JogosH2>
                    </JogosCard> 
                
                    
                </JogosWrapper>
            </JogosContainer>
        </>
    )
}

export default Jogos

/* <div onClick={() => {setAbreModal(false)}}>
<JogosCard style={{color: "white",backgroundColor: "#3F8144"}} onClick={() => {setTela(3);setAbreModal(true);}}>
    <JogosIcon src={icon1}/>
    <JogosH2>Jogo da velha</JogosH2>
</JogosCard>

<JogosCard style={{color: "white",backgroundColor: "#3F8144"}} onClick={() => {setTela(3);setAbreModal(true);}}>
                        <JogosIcon src={icon1}/>
                        <JogosH2>Caça Palavras</JogosH2>
                    </JogosCard>
*/