import React, { useState, useEffect } from 'react'

import {Parent,Child,Fundo,Ganhou,Button} from './tabuleiroElements'
import imagem from './img/Logo17.png'



const ImgCard = {
    "Ods1": require("./img/ods1.png"),
    "Ods2": require("./img/ods2.png"),
    "Ods3": require("./img/ods3.png"),
    "Ods4": require("./img/ods4.png"),
    "Ods5": require("./img/ods5.png"),
    "Ods6": require("./img/ods6.png"),
    "Ods7": require("./img/ods7.png"),
    "Ods8": require("./img/ods8.png"),
    "Ods9": require("./img/ods9.png"),
    "Ods10": require("./img/ods10.png"),
    "Ods11": require("./img/ods11.png"),
    "Ods12": require("./img/ods12.png"),
    "Ods13": require("./img/ods13.png"),
    "Ods14": require("./img/ods14.png"),
    "Ods15": require("./img/ods15.png"),
    "Ods16": require("./img/ods16.png"),
    "Ods17": require("./img/ods17.png")
  };


const Memoria = () => {

    const [imagens, setImagens] = useState([]);
    const [imagensAbertas,setImagensAbertas] = useState([])
    const [acertos,setAcertos] = useState([])
    const [ganhou, setGanhou] = useState(false)

    useEffect(() => {
       resetar();
      }, []);

    function resetar(){
        var temp = [{ods: 1, img: ImgCard.Ods1},
            {ods: 2, img: ImgCard.Ods2},
            {ods: 3, img: ImgCard.Ods3},
            {ods: 4, img: ImgCard.Ods4},
            {ods: 5, img: ImgCard.Ods5},
            {ods: 6, img: ImgCard.Ods6},
            {ods: 7, img: ImgCard.Ods7},
            {ods: 8, img: ImgCard.Ods8},
            {ods: 9, img: ImgCard.Ods9},
            {ods: 10, img: ImgCard.Ods10},
            {ods: 11, img: ImgCard.Ods11},
            {ods: 12, img: ImgCard.Ods12},
            {ods: 13, img: ImgCard.Ods13},
            {ods: 14, img: ImgCard.Ods14},
            {ods: 15, img: ImgCard.Ods15},
            {ods: 16, img: ImgCard.Ods16},
            {ods: 17, img: ImgCard.Ods17},
            {ods: 1, img: ImgCard.Ods1},
            {ods: 2, img: ImgCard.Ods2},
            {ods: 3, img: ImgCard.Ods3},
            {ods: 4, img: ImgCard.Ods4},
            {ods: 5, img: ImgCard.Ods5},
            {ods: 6, img: ImgCard.Ods6},
            {ods: 7, img: ImgCard.Ods7},
            {ods: 8, img: ImgCard.Ods8},
            {ods: 9, img: ImgCard.Ods9},
            {ods: 10, img: ImgCard.Ods10},
            {ods: 11, img: ImgCard.Ods11},
            {ods: 12, img: ImgCard.Ods12},
            {ods: 13, img: ImgCard.Ods13},
            {ods: 14, img: ImgCard.Ods14},
            {ods: 15, img: ImgCard.Ods15},
            {ods: 16, img: ImgCard.Ods16},
            {ods: 17, img: ImgCard.Ods17},];    
        var img = temp.sort(() => Math.random() - 0.5);
        setImagens(img)
        setImagensAbertas([])
        setAcertos([])
        setGanhou(false)
    }

    function handleClick(ods){
        var temp = imagensAbertas;
        if((temp.length <= 1) && (!imagensAbertas.includes(ods)) && (!acertos.includes(imagens[ods].ods))){
            var arr = [...temp,ods]
            setImagensAbertas(arr)
            if((imagens[ods].ods === imagens[arr[0]].ods) && (arr.length === 2)){
                var acertados = [...acertos,imagens[ods].ods];
                setAcertos(acertados)
                setImagensAbertas([])
                if(acertados.length === imagens.length){

                }
            }else{
            console.log(temp.length)
              if(temp.length === 1) {
                setTimeout(function(){console.log(imagensAbertas.length); if(imagensAbertas.length === 1){setImagensAbertas([]);}  }, 2000);
              }
            }
        }
    }

    return (
        <>  
          {ganhou ? 
             <Ganhou>
                 <p>Parabéns, você ganhou!</p>
                 <Button onClick={() => {resetar();}}>Jogar Novamente</Button>
             </Ganhou>
           :    <Parent>
                  {imagens.map(function(item, i){
                    return <Child onClick={() => {handleClick(i)}}>
                             <Fundo src={(imagensAbertas.includes(i) || acertos.includes(item.ods)) ? item.img.default : imagem}></Fundo>
                           </Child>
                  })}
                </Parent>
          }
        </>
    )
}

export default Memoria
