import React from 'react'
import SobreInfo from '../components/Sobre';

const Sobre = () => {

    return (
        <>
            <SobreInfo/>
        </>
    )
}

export default Sobre