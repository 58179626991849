import React from 'react'
import { 
    JogosContainer,
    JogosH1,
    JogosWrapper,
    JogosCard,
    JogosIcon,
    Link
} from './VideoElements'

const Jogos = () => {
    return (
        <>
            <JogosContainer>
                <JogosH1>Vídeos</JogosH1>
                <JogosWrapper>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=3CXBXdXoq6g" target="_blank">
                            <JogosIcon src='./images/videos/intro_ods.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=JMm1c1espT0" target="_blank">
                            <JogosIcon src='./images/videos/ods1.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=kcOVXAIScSM" target="_blank">
                            <JogosIcon src='./images/videos/ods2.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=a3-OvmUY-Hc" target="_blank">
                            <JogosIcon src='./images/videos/ods3.svg'/>
                        </Link>
                    </JogosCard>
                </JogosWrapper>
                <JogosWrapper gridDisplay="1fr 1fr 1fr">
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=ic9yUITcjtc" target="_blank">
                            <JogosIcon src='./images/videos/ods4.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=lxdI7ZWdDX0" target="_blank">
                            <JogosIcon src='./images/videos/ods5.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=xJTxTNZeTJI" target="_blank">
                            <JogosIcon src='./images/videos/ods6.svg'/>
                        </Link>
                    </JogosCard>
                </JogosWrapper>
                <JogosWrapper>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=5uHNMtGFub4" target="_blank">
                            <JogosIcon src='./images/videos/ods7.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=o729ffNeGms" target="_blank">
                            <JogosIcon src='./images/videos/ods8.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=HX7L7LFxaNo" target="_blank">
                            <JogosIcon src='./images/videos/ods9.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=zS5wNavO494" target="_blank">
                            <JogosIcon src='./images/videos/ods10.svg'/>
                        </Link>
                    </JogosCard>
                </JogosWrapper>
                <JogosWrapper gridDisplay="1fr 1fr 1fr">
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=159V2pXL9g8" target="_blank">
                            <JogosIcon src='./images/videos/ods11.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=YekxeMMxaCA" target="_blank">
                            <JogosIcon src='./images/videos/ods12.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=bmuStvMvMYM" target="_blank">
                            <JogosIcon src='./images/videos/ods13.svg'/>
                        </Link>
                    </JogosCard>
                </JogosWrapper>
                <JogosWrapper>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=fSEufuIS0JQ" target="_blank">
                            <JogosIcon src='./images/videos/ods14.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=QavgEIpOKus" target="_blank">
                            <JogosIcon src='./images/videos/ods15.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=AwrKQbwCPWg" target="_blank">
                            <JogosIcon src='./images/videos/ods16.svg'/>
                        </Link>
                    </JogosCard>
                    <JogosCard>
                        <Link href="https://www.youtube.com/watch?v=keyc9HDsL-M" target="_blank">
                            <JogosIcon src='./images/videos/ods17.svg'/>
                        </Link>
                    </JogosCard>
                </JogosWrapper>
            </JogosContainer>
        </>
    )
}

export default Jogos
