import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 1000;
  body.noScroll { /* ...or body.dialogShowing */
    overflow: hidden;
  }
`

export const ModalWrapper = styled.div`
  width: 800px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  z-index: 1000;

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    width: 340px;
    height: 550px;
    top: 55%;
  }
`;

export const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: ${props => props.color || '#000'};

  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  border-radius: 10px;
  background: ${props => props.backgroundColor};
`;
/*h1{
    padding-top: 190px;
    font-size: 18px;
  }
  p {
    margin-bottom: 1rem;
    padding: 30px;
    font-size: 14px;
  }
  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
  }*/
export const ContentText = styled.div`
  //margin-top: 100px;
  margin-left: 15px;
  margin-right: 15px;
  max-height: 450px;
  overflow-y: auto;

  h1{
    font-size: 18px;
    margin-bottom: 12px;
  }
  h2{
    font-size: 1em;
    text-align-last: center;
    margin-bottom: 12px;
  }
  h3{
    font-size: 1em;
  }
  ul{
    margin-bottom: 20px;
  }
  li{
    //line-height: 1.0;
    font-size: 13px;
    counter-increment: step-counter;
    position: relative;
    padding-left: 10px;
  }
  p {
    margin-bottom: 1rem;
    font-size: 14px;
  }
  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  @media screen and (max-width: 768px){
    max-height: 500px;
    h1, h2, h3, li, p{
      color: #fff;
    }
  }
`;

export const ModalContentSaibaMais = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  border-radius: 10px;
  background: ${props => props.backgroundColor};
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  @media screen and (max-width: 768px){
    color: #fff;
  }
`;

export const ContainerCloseModalButton = styled.div`
  height: 50px;
`;


/*export const OdsName = styled.span`
  display: flex;
  position: absolute;
  top: 28px;
  left: 20px;
  padding: 0;
  z-index: 10;
`;*/