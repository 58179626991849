import React from 'react';
import ReactDom from 'react-dom';
import { FcCheckmark } from 'react-icons/fc'; 
import { BsCheck } from 'react-icons/bs';
import { 
  ModalContainer, 
  ModalWrapper, 
  ModalImg, 
  ModalContent, 
  CloseModalButton,
  ModalContentSaibaMais,
  ContentText,
  ContainerCloseModalButton
} from './ModalElements' 

//<button onClick={() => setContainer("saibaMais")}>Saiba mais</button>

export default function Modal({ open, onClose, more, onGoBack, dados }) {
  if (!open) return null
  const icone = window.innerWidth > 700 ? <FcCheckmark/> : <BsCheck/>;
  const list1CreatedFromMap = dados.firstTopic.map((item, i) => (<li key={item + i}> {icone} {item}</li>));
  const list1 = (
    <ul>{list1CreatedFromMap}</ul> // `myArrCreatedFromMap` will evaluate to a list of `<li>` elements
  )
  const list2CreatedFromMap = dados.secondTopic.map((item, i) => (<li key={item + i}> {icone} {item}</li>));
  const list2 = (
    <ul>{list2CreatedFromMap}</ul> 
  )
  return ReactDom.createPortal(
    <>
      <ModalContainer>
        <ModalWrapper>
          <ModalImg src={dados.image} alt='imagem' color={dados.color}/>
            {more ? (
              <ModalContentSaibaMais backgroundColor={window.innerWidth > 700 ? "#fff" : dados.color}>
                <ContainerCloseModalButton>
                  <CloseModalButton
                    aria-label='Close modal'
                    onClick={onClose}
                  />
                </ContainerCloseModalButton>
                <ContentText>
                  <h2>O QUE PODE SER FEITO PARA AJUDAR?</h2>
                  <h3>CRIANÇAS</h3>
                  <hr/>
                  {list1}
                  <h3>COMUNIDADE EM GERAL E PODER PÚBLICO</h3>
                  <hr/>
                  {list2}
                  <button onClick={onGoBack}>Voltar</button>
                </ContentText>
              </ModalContentSaibaMais>
            ):(  
              <ModalContent backgroundColor={window.innerWidth > 700 ? "#fff" : dados.color}>
                <ContainerCloseModalButton>
                  <CloseModalButton
                    aria-label='Close modal'
                    onClick={onClose}
                  />
                </ContainerCloseModalButton>
                <ContentText>
                  <h1>{dados.h1Text}</h1>
                  <p>{dados.mainText}</p>
                  <button onClick={onGoBack}>Saiba mais</button>
                </ContentText>
              </ModalContent>
            )}  
        </ModalWrapper>
      </ModalContainer>
    </>,
    document.getElementById('modal')
  )
}